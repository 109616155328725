import { useContext, useEffect, useRef, useState } from "react";

import classes from "./ViewInvoice.module.css";

import { sanitizeNumber } from "../../utils/sanitizeNumber";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";

// * Custom utils
import { toMoney } from "../../utils/toMoney";

import CustomInput from "../../components/CustomInput";

import printIcon from "../../assets/printer_icon.png";
import editIcon from "../../assets/edit_icon.png";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";

// * Date Picker
import { DataContext } from "../../utils/DataContext";
import CustomButton from "../../components/CustomButton";
import useLanguage from "../../utils/useLanguage";
import Loader from "../../components/Loader";

export default function EditContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");

  const { id } = useParams();

  const { currentDirection } = useLanguage();

  const [data, setData] = useState();
  const [editMode, setEditMode] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const { selectedSupplier, currentBalance, setCurrentBalance } =
    useContext(DataContext);

  const [tempBalanceAfterUpdate, setTempBalanceAfterUpdate] = useState(0);
  const [newRecord, setNewRecord] = useState({ date: new Date() });

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = () => {
    setEditMode(true);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setError("");
    setNewRecord(data);
  };

  // Prevent entering numbers in input field
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      // Ensure userId is stored as a number

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getContainer/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.status === 200) {
        setData(response?.data);
        setNewRecord(response?.data);
        setTempBalanceAfterUpdate(response?.data?.balanceAfterUpdate);
        setCurrentBalance({ value: response?.data?.currentBalance });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleConfirmEdit = async () => {
    // Retrieve and parse the current balance from the context or default to 0

    // Check if the invoice data has changed by comparing key fields
    const isDateEqual = data?.modifiedAt === newRecord.modifiedAt;
    const isAmountInSupCurEqual =
      parseFloat(data?.amountInSupCur) === parseFloat(newRecord.amountInSupCur);
    const isNotesEqual = data?.notes === newRecord?.notes;

    if (isDateEqual && isAmountInSupCurEqual && isNotesEqual) {
      setEditMode(false);
      return;
    }

    const currentBalanceValue = parseFloat(currentBalance?.value) || 0;

    // // Retrieve and parse the old amount from the invoice data (before any edits)
    const sanitizedAmountInSupCur = sanitizeNumber(data?.amountInSupCur) || 0;

    const amountInSupCur = parseFloat(sanitizedAmountInSupCur) || 0;

    // // Sanitize and parse the new amount input by the user
    const sanitizedNewAmountInSupCur =
      sanitizeNumber(newRecord.amountInSupCur) || 0;

    const newAmountInSupCur = parseFloat(sanitizedNewAmountInSupCur) || 0;

    // Calculate the new balance after the update (subtract old amount, add new amount)
    const calcCurrentBalance =
      +currentBalanceValue + +amountInSupCur - +newAmountInSupCur;

    // Retrieve the authentication token and user ID from local storage
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    try {
      setLoading("editContainer");
      const editContainer = await axios.patch(
        `${process.env.REACT_APP_URL}/editContainer/${data?.id}`,
        {
          amountInSupCur: newAmountInSupCur,
          balanceAfterUpdate: tempBalanceAfterUpdate,
          modifiedAt: new Date(newRecord?.modifiedAt),
          notes: newRecord?.notes,
          currentBalance: calcCurrentBalance,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": data?.supplierId,
          },
        }
      );

      if (!editContainer) {
        return;
      }

      console.log("editContainer?.data", editContainer?.data);

      setData((prev) => {
        console.log(editContainer);
        return { ...prev, ...editContainer?.data?.editedContainer };
      });

      setCurrentBalance((prev) => ({ ...prev, value: calcCurrentBalance }));
    } catch (error) {
      // Log and display any errors that occur during the update process
      console.error("Error updating invoice:", error);
      setError("Error updating invoice.");
    } finally {
      setLoading("");
    }
    setEditMode(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setNewRecord((prevRow) => {
      let updatedRow = { ...prevRow, [name]: value };

      if (name === "amountInSupCur") {
        if (value) {
          setTempBalanceAfterUpdate(data?.balanceBeforeUpdate - value);
          // setTempBalanceAfterUpdate((prev) => {
          //   // console.log("prev", prev);
          //   // console.log("result: ", newRecord.balanceBeforeUpdate);
          // console.log("result: ", data?.balanceBeforeUpdate - value);
          //   // console.log("result: ", value);
          // });
          // setTempCurrentBalance((prev) => ({
          //   ...prev,
          //   value: currentBalance?.value - parseFloat(sanitizeNumber(value)),
          // }));
        }
      }
      return updatedRow;
    });
  };

  // console.log("Current balance", tempCurrentBalance);

  const handleDateChange = (e) => {
    const date = e.target.value;
    setNewRecord((prevRecord) => ({
      ...prevRecord,
      modifiedAt: date,
    }));
  };

  return (
    <>
      <main className={classes.viewInvoicePage}>
        <div className="e-container">
          <div className="user-logout">
            <CustomButton
              title={t("back")}
              variant="blank"
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={14}
              onClick={() => navigate("/invoiceManagement")}
            />

            <div className={classes.user} style={{ marginTop: "25px" }}>
              <p className={classes.userTitle}>{`${t("user")}: `}</p>
              <p className={classes.userName}>
                {` ${localStorage.getItem("username")}`}
              </p>
            </div>
          </div>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("supplier")}: </span>
              <span className={`${classes.boldText} ${classes.breakWord}`}>
                {selectedSupplier?.name}
              </span>
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("Date")}: </span>
              {editMode ? (
                <CustomInput
                  type="date"
                  name="date"
                  value={
                    newRecord?.modifiedAt
                      ? new Date(newRecord.modifiedAt)
                          .toISOString()
                          .split("T")[0]
                      : "Invalid Date"
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.modifiedAt
                    ? new Date(data.modifiedAt).toISOString().split("T")[0]
                    : "Invalid Date"}
                </span>
              )}
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>
                {t("amountInSupplierCurrency") + ":"}
              </span>
              {editMode ? (
                <CustomInput
                  name="amountInSupCur"
                  value={newRecord?.amountInSupCur}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleInputChange}
                  type="money"
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.amountInSupCur)} {selectedSupplier?.currency}
                </span>
              )}
            </div>

            {editMode ? (
              <div className={classes.col}>
                <span className={classes.colTitle}>
                  {t("balanceBeforeImport")}:{" "}
                </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.balanceBeforeUpdate)}{" "}
                  {selectedSupplier?.currency}
                </span>
              </div>
            ) : (
              <div className={classes.col}>
                <span className={classes.colTitle}>
                  {t("balanceAfterImport")}:{" "}
                </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.balanceBeforeUpdate)}{" "}
                  {selectedSupplier?.currency}
                </span>
              </div>
            )}
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("notes")}: </span>
              {editMode ? (
                <CustomInput
                  name="notes"
                  value={newRecord?.notes}
                  onChange={handleInputChange}
                />
              ) : (
                <span className={`${classes.breakWord}`}>{data?.notes}</span>
              )}
            </div>
            {editMode ? (
              <div className={classes.col}>
                <span className={classes.colTitle}>
                  {t("balanceAfterUpdate")}:{" "}
                </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(tempBalanceAfterUpdate)} {selectedSupplier?.currency}
                </span>
              </div>
            ) : (
              <div className={classes.col}>
                <span className={classes.colTitle}>
                  {t("balanceAfterUpdate")}:{" "}
                </span>
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.balanceAfterUpdate)}{" "}
                  {selectedSupplier?.currency}
                </span>
              </div>
            )}
          </section>
          {loading === "editContainer" ? <Loader /> : null}
          <section className={classes.actions}>
            {editMode ? (
              <>
                <CustomButton
                  title={t("cancel")}
                  onClick={cancelEdit}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
                <CustomButton
                  title={t("confirm")}
                  onClick={handleConfirmEdit}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loading === "editContainer"}
                />
              </>
            ) : (
              <>
                <CustomButton
                  variant="blank"
                  title={t("edit")}
                  onClick={handleEdit}
                  icon={editIcon}
                  iconSize={18}
                />
                {setEditMode ? (
                  <>
                    <CustomButton
                      title={t("print")}
                      onClick={handlePrint}
                      variant="gray"
                      icon={printIcon}
                      iconSize={18}
                    />
                  </>
                ) : null}
              </>
            )}
          </section>
        </div>
      </main>
    </>
  );
}
