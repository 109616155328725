// src/pages/organization-pages/AddShippedContainerModal.js
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { DataContext } from "../../utils/DataContext";
import { UserAuth } from "../../utils/AuthContext";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";

import ErrorBox from "../../components/ErrorBox";
import Loader from "../../components/Loader";
import CustomInput from "../../components/CustomInput";
import Dropdown from "../../components/Dropdown";
import CustomButton from "../../components/CustomButton";
import addIcon from "../../assets/add_icon.png";

import { getCleanedInvoiceNumber } from "../../utils/getCleanedInvoiceNumber";
import Modal from "../../components/Modal";
import classes from "../../components/Modal.module.css";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import { sanitizeNumber } from "../../utils/sanitizeNumber";

const AddShippedContainerModal = ({
  inputs,
  setInputs,
  setShowAddShippedContainerModal,
  onClose,
}) => {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const [formData, setFormData] = useState({
    containerNumber: "",
    expectedArrivalDate: new Date(),
    finalDestination: "",

    shipper: "",
    material: "",
    numberOfBoxes: 0,
    unitPrice: 0,
    exitDate: new Date(),
    dateOfEntry: new Date(),
    shippingDate: new Date(),
    permitsReceivingDate: new Date(),
    howWasPapersReceived: "",
    amountForSupplier: "",
    abstractAmountForCustomer: "",
    finalAmountForCustomer: "",
    notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    setInputs((prev) =>
      Array.isArray(prev) ? [...prev, formData] : [formData]
    );
    onClose();
  };

  const {
    selectedCustomer,
    subCompanies,
    selectedSubCompany,
    handleSelectSubCompany,
    getSubCompanies,
  } = useContext(DataContext);

  const [howWasPapersReceivedStatus, setHowWasPapersReceivedStatus] = useState({
    title: "receivedByUs",
    value: "receivedByUs",
  });

  const [loadingModal, setLoadingModal] = useState(false);

  // Prevent entering numbers in input field
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  const handleAddInput = () => {
    setShowAddShippedContainerModal(false);
    setInputs([
      ...inputs,
      {
        containerNumber: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",

        shipper: "",
        material: "",
        numberOfBoxes: 0,
        unitPrice: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        howWasPapersReceived: "",
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      },
    ]);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    // Update `formData` instead of trying to modify `inputs` directly.
    setFormData((prev) => ({ ...prev, [name]: new Date(value) }));
  };

  const handleCancel = () => {
    setShowAddShippedContainerModal(false);
    setInputs([
      {
        containerNumber: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",
        updateCounter: 0,
        shipper: "",
        material: "",
        numberOfBoxes: 0,
        unitPrice: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        howWasPapersReceived: "",
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      },
    ]);
  };

  return (
    <Modal
      title="addContainer"
      onClose={handleCancel}
      footer={
        <>
          {loadingModal ? <Loader /> : null}
          <section className={classes.actions}>
            <CustomButton
              onClick={handleSave}
              title={t("confirm")}
              icon={saveIcon}
              iconSize={16}
              disabled={loadingModal}
            />
            <CustomButton
              title={t("cancel")}
              onClick={handleCancel}
              variant="blank"
              icon={closeIcon}
              iconSize={14}
            />
          </section>
        </>
      }
    >
      <div
        className={`${classes.flexableForm} ${classes.flexableFormWithPadding}`}
      >
        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("containerNumber")}: </span>
            <div className={classes.invoiceNumberContainer}>
              <CustomInput
                name="containerNumber"
                value={formData?.containerNumber}
                onChange={(e) => handleInputChange(e)}
                flex={true}
              />
            </div>
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("amountForSupplier")}: </span>
            <CustomInput
              name="amountForSupplier"
              value={formData?.amountForSupplier}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("expectedArrivalDate")}: </span>
            <CustomInput
              name="expectedArrivalDate"
              type="date"
              flex={true}
              value={formData?.expectedArrivalDate.toISOString().split("T")[0]}
              onChange={(e) => handleDateChange(e)}
            />
          </div>
        </section>
        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("finalDestination")}: </span>
            <CustomInput
              name="finalDestination"
              value={formData?.finalDestination}
              onChange={(e) => handleInputChange(e)}
              flex={true}
            />
          </div>
        </section>

        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("material")}: </span>
            <CustomInput
              name="material"
              value={formData?.material}
              onChange={(e) => handleInputChange(e)}
              flex={true}
            />
          </div>
        </section>
        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("numberOfBoxes")}: </span>
            <CustomInput
              name="numberOfBoxes"
              value={formData?.numberOfBoxes || ""}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>
        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("unitPrice")}: </span>
            <CustomInput
              name="unitPrice"
              value={formData?.unitPrice || ""}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>
        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("shipper")}: </span>
            <CustomInput
              name="shipper"
              value={formData?.shipper}
              onChange={(e) => handleInputChange(e)}
              flex={true}
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("shippingDate")}: </span>
            <CustomInput
              name="shippingDate"
              type="date"
              flex={true}
              value={
                formData?.shippingDate
                  ? formData?.shippingDate.toISOString().split("T")[0]
                  : ""
              }
              onChange={(e) => handleDateChange(e)}
            />
          </div>
        </section>

        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("exitDate")}: </span>
            <CustomInput
              name="exitDate"
              type="date"
              flex={true}
              value={
                formData?.exitDate
                  ? formData?.exitDate.toISOString().split("T")[0]
                  : ""
              }
              onChange={(e) => handleDateChange(e)}
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("abstractAmountForCustomer")}: </span>
            <CustomInput
              name="abstractAmountForCustomer"
              value={formData?.abstractAmountForCustomer}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("finalAmountForCustomer")}: </span>
            <CustomInput
              name="finalAmountForCustomer"
              value={formData?.finalAmountForCustomer}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("dateOfEntry")}: </span>
            <CustomInput
              name="dateOfEntry"
              type="date"
              flex={true}
              value={
                formData?.dateOfEntry
                  ? formData?.dateOfEntry.toISOString().split("T")[0]
                  : ""
              }
              onChange={(e) => handleDateChange(e)}
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("howWasPapersReceived")}: </span>
            <Dropdown
              options={[
                { title: "receivedByUs", value: "receivedByUs" },
                { title: "receivedByCustomer", value: "receivedByCustomer" },
              ]}
              selected={formData?.howWasPapersReceived || {}}
              setSelected={(status) =>
                handleInputChange({
                  target: { name: "howWasPapersReceived", value: status },
                })
              }
              flex
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("permitsReceivingDate")}: </span>
            <CustomInput
              name="permitsReceivingDate"
              type="date"
              flex={true}
              value={
                formData?.permitsReceivingDate
                  ? formData?.permitsReceivingDate.toISOString().split("T")[0]
                  : ""
              }
              onChange={(e) => handleDateChange(e)}
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span className={classes.notesClass}>{t("notes")}: </span>
            <CustomInput
              name="notes"
              type="textarea"
              value={formData?.notes || ""}
              onChange={(e) => handleInputChange(e)}
              flex={true}
            />
          </div>
        </section>
        {/* <PDFUploader
            ref={pdfUploaderRef}
            invoiceNumber={data?.invoiceNumber}
            invoiceDocs={invoiceDocs}
            setInvoiceDocs={setInvoiceDocs}
            mode="upload"
            documents={data?.documents}
            tempDocs={invoiceDocs}
            setTempDocs={setInvoiceDocs}
        /> */}
      </div>
    </Modal>
  );
};

export default AddShippedContainerModal;
