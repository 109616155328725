import { useState, useEffect, useContext } from "react";
import { Container, Typography } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import useLanguage from "../../utils/useLanguage";
import nextIcon from "../../assets/next_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import editIcon from "../../assets/edit_icon.png";
import closeIcon from "../../assets/close_icon.png";
import saveIcon from "../../assets/save_icon.png";
import addIcon from "../../assets/add_icon.png";
import { UserAuth } from "../../utils/AuthContext";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import ErrorBox from "../../components/ErrorBox";
import { DataContext } from "../../utils/DataContext";
import BackToDashboard from "../../components/BackToDashboard";
import classes from "./CustomersPage.module.css";
import CustomCheckbox from "../../components/CustomCheckbox";
import ErrorMessage from "../../components/ErrorMessage";
import { fontWeight } from "@mui/system";
import Dropdown from "../../components/Dropdown";

export default function CustomersPage() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  // const [networkError, setNetworkError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerModal, setCustomerModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [customerFields, setCustomerFields] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Added pagination state
  const [totalPages, setTotalPages] = useState(1); // Added totalPages state
  const [totalRecords, setTotalRecords] = useState(0); // Added totalRecords state
  const [loadingModal, setLoadingModal] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [newCustomerFields, setNewCustomerFields] = useState({
    name: "",
    phoneNumber: null,
    email: null,
    address: null,
  });

  const userId = parseInt(localStorage.getItem("userId"), 10);
  const organizationId = localStorage.getItem("organizationId");

  const { userData } = useContext(UserAuth);
  const {
    loading,
    setLoading,
    currencies,
    supplierCurrency,
    setSupplierCurrency,
  } = useContext(DataContext);

  useEffect(() => {
    const getAllCustomers = async () => {
      const req = await getCustomers();
    };

    getAllCustomers();
  }, [currentPage, searchTerm, userData]);

  const getCustomers = async (page, limit) => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken") ||
      localStorage.getItem("token");

    try {
      setLoading(true);
      const customers = await axios.get(
        `${process.env.REACT_APP_URL}/getCustomers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Organization-Id": organizationId,
          },
          params: {
            page: currentPage,
            limit: 5,
            search: searchTerm,
          },
        }
      );

      if (customers.data && customers.data.customers) {
        setCustomers(customers.data.customers);
        setFilteredCustomers(customers.data.customers);
        setTotalPages(customers.data.totalPages);
        setTotalRecords(customers.data.totalCustomers);
      } else {
        console.error("Failed to get the users");
      }
      if (!customers) {
        console.error("Failed to get the cusotmers", error);
      }

      const existingCustomers = customers?.data?.customers;

      setCustomers(existingCustomers);
      setLoading(false);

      if (existingCustomers.length) {
        setSelectedCustomer(existingCustomers[0]);
      }
    } catch (error) {
      // setNetworkError(true);
      setLoading(false);
      console.error("Failed to get the Customers", error);
    }
  };

  useEffect(() => {
    setFilteredCustomers(
      customers.filter((customer) =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, customers]);

  const openCustomerModal = (customer) => {
    setSelectedCustomer(customer);
    setCustomerFields({
      name: customer.name,
      email: customer.email,
      phoneNumber: customer.phoneNumber,
      address: customer.address,
    });
    setIsEditing(false);
    setCustomerModal(true);
  };

  const validateName = (name) => {
    if (!name.trim()) {
      return t("inputFieldCannotBeEmpty");
    }
    if (name.length < 3) {
      return t("nameTooShort");
    }
    return "";
  };

  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const [errorMessage2, setErrorMessage2] = useState("");

  const handleContinue = () => {
    if (selectedCustomers.length === 0) {
      setErrorMessage2(t("selectAtLeastOneCustomer"));
    } else {
      setErrorMessage2("");
      console.log("Proceeding with selected customers:", selectedCustomers);
      goToMessagePage(selectedCustomers);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedCustomers(
      (prev) =>
        prev.includes(id)
          ? prev.filter((customerId) => customerId !== id) // Deselect
          : [...prev, id] // Select
    );
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedCustomers([]);
    } else {
      setSelectedCustomers(filteredCustomers.map((customer) => customer.id));
    }
    setAllSelected(!allSelected);
    // we need endpoint select all
  };

  const [show2btns, setShow2btns] = useState(false);
  const showTowBtns = () => {
    setShow2btns(true);
    setShowCheckboxes(!showCheckboxes);
  };

  const cancel2Btns = () => {
    setShow2btns(false);
    setShowCheckboxes(!showCheckboxes);
    setErrorMessage2("");
  };

  const goToMessagePage = (selectedCustomers) => {
    // Ensure filteredCustomers is always an array
    const safeFilteredCustomers = Array.isArray(filteredCustomers)
      ? filteredCustomers
      : [];

    console.log("selectedCustomers : ", selectedCustomers);
    console.log("filteredCustomers : ", filteredCustomers);
    const customerNames = selectedCustomers.map((customerId) => {
      const customer = safeFilteredCustomers.find(
        (customer) => customer.id === customerId
      );
      return customer ? customer.name : "Unknown";
    });

    navigate("/message-page", {
      state: {
        selectedCustomers: selectedCustomers,
        filteredCustomers: customerNames, // Pass the customer names
      },
    });
  };

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setCustomerFields((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

    if (fieldName === "name") {
      const error = validateName(value);
      setErrorMessage(error);
    } else {
      setErrorMessage("");
    }
  };
  const handleSaveCustomer = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    // Validate name field
    if (!customerFields.name?.trim()) {
      setErrorMessage(t("inputFieldCannotBeEmpty"));
      return; // Stop execution if validation fails
    }

    if (customerFields.name.length < 3) {
      setErrorMessage(t("nameTooShort")); // Replace with appropriate translation
      return;
    }

    try {
      setLoadingModal(true);
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/updateCustomer/${selectedCustomer.id}`,
        {
          customerName: customerFields.name,
          phoneNumber: customerFields.phoneNumber,
          email: customerFields.email,
          address: customerFields.address,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      if (response.data.message === "Customer updated successfully") {
        await getCustomers();
        setCustomerModal(false);
        console.log("Customer updated successfully");
      } else {
        console.error("Failed to update Customer:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating Customer:", error);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleCancel = () => {
    setCustomerModal(false);
    setSelectedCustomer(null);
  };

  const openAddCustomerModal = () => {
    setAddCustomerModal(true);
    setNewCustomerFields({
      name: "",
    });
  };

  const handleNewCustomerInputChange = (e, fieldName) => {
    const value = fieldName === "isBlocked" ? e.target.checked : e.target.value;
    setNewCustomerFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const handleCreateCustomer = async (e) => {
    e.preventDefault();

    try {
      // Determine the language based on currentDirection
      const language = currentDirection === "rtl" ? "ar" : "en";

      //  Empty fields validation
      if (!newCustomerFields.name) {
        setError(true);
        setErrorMessage(t("emptyInputFields"));
        return;
      }

      // // Email validation regex
      // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // if (newUserFields.userEmail) {
      //   if (!emailPattern.test(newUserFields.userEmail)) {
      //     setError(true);
      //     setErrorMessage(t("invalidEmail"));
      //     return;
      //   }
      // }

      setLoadingModal(true);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/createCustomer`,
        {
          email: newCustomerFields.email,
          customerName: newCustomerFields.name,
          phoneNumber: newCustomerFields.phoneNumber,
          address: newCustomerFields.address,
          organizationId: Number(userData?.organizationId),
          userId: Number(userData?.userId),
          currency: supplierCurrency.value,
          language,
        }
      );

      setMessage(response.data.message);
      setTotalRecords((prev) => prev + 1);
      if (response.data.message === "Customer created successfully") {
        // Close the modal and refresh the user list
        setError(false);
        setErrorMessage("");
        setAddCustomerModal(false);
        await getCustomers();
      }
      setError(false);
      setErrorMessage("");
      setAddCustomerModal(false);
      setLoadingModal(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorKey = error.response.data.error;
        console.log("errorKey:", errorKey);
        // Map backend errors to user-friendly messages
        switch (errorKey) {
          case "emailAlreadyInUse":
            setError(true);
            setErrorMessage(t("emailAlreadyInUse"));
            break;
          case "invalidPhoneNumber":
            setError(true);
            setErrorMessage(t("invalidPhoneNumber"));
            break;
          default:
            setError(true);
            setErrorMessage(t("unexpectedError"));
            break;
        }
      } else {
        console.error("Network or other error:", error.message);
        setError(true);
        // setErrorMessage(t("networkError"));
      }
      setLoadingModal(false);
    }
  };

  const handleCancelAddCustomer = () => {
    setError(false);
    setErrorMessage("");
    setAddCustomerModal(false);
  };

  return (
    <div className="e-container">
      <div
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank-fit-content"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
            to={
              userData?.userType === "user"
                ? "/dashboard"
                : "/organization-dashboard"
            }
          />
        </div>

        {errorMessage2 && <ErrorBox>{errorMessage2}</ErrorBox>}

        <div className="users-title-section">
          <h4 className="title">
            {t("customers", { defaultValue: "customers" })}
          </h4>

          {userData?.userId ? (
            <div className={classes.flexBtns}>
              <div className={classes.maxWidth}>
                <CustomButton
                  title={t("createCustomer")}
                  variant="blank"
                  icon={addIcon}
                  iconSize={20}
                  onClick={openAddCustomerModal}
                />
              </div>
              {show2btns ? (
                <div className={classes.flexRow}>
                  <CustomButton
                    title={t("cancel")}
                    variant="blank"
                    icon={closeIcon}
                    iconSize={15}
                    onClick={cancel2Btns}
                  />
                  <CustomButton
                    title={t("continue")}
                    iconSize={20}
                    onClick={handleContinue}
                  />
                </div>
              ) : (
                <CustomButton
                  title={t("contactCostumers")}
                  variant="blank"
                  iconSize={20}
                  onClick={showTowBtns}
                />
              )}
            </div>
          ) : null}
        </div>
        <CustomInput
          name="name"
          value={searchTerm}
          placeholder={searchTerm ? "" : t("search")}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {loading ? (
          <Loader />
        ) : filteredCustomers.length === 0 ? (
          <p>{t("noCustomersFound")}</p>
        ) : (
          <ul className="users-list">
            {showCheckboxes && (
              <div className={classes.userListItem}>
                <CustomCheckbox
                  checked={allSelected}
                  onChange={handleSelectAll}
                />
                <label style={{ fontWeight: "700" }}>{t("selectAll")}</label>
              </div>
            )}
            {errorMessage2 && <ErrorMessage>{errorMessage2}</ErrorMessage>}
            {filteredCustomers.map((customer) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {showCheckboxes && (
                  <CustomCheckbox
                    checked={selectedCustomers.includes(customer.id)}
                    onChange={() => handleCheckboxChange(customer.id)}
                    color="primary"
                  />
                )}
                <li
                  key={customer.id}
                  onClick={() => openCustomerModal(customer)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="user-list-item"
                >
                  <p style={{ fontWeight: "700", marginTop: 20 }}>
                    {customer.name}
                  </p>
                </li>
                {/* <li
                  className="user-list-item"
                  key={user.id}
                  onClick={() => openUserModal(user)}
                  style={{ cursor: "pointer" }}
                >
                  <p className="user-username">
                    {user.username || "No Name Available"}
                  </p>
                  {user.email && (
                    <Typography variant="subtitle1" color="textSecondary">
                      {user.email}
                    </Typography>
                  )}
                </li> */}
              </div>
            ))}
          </ul>
        )}

        {/* Paginations  */}
        <section className="pagination">
          <div className="pagination-buttons">
            <CustomButton
              variant="blank"
              title={t("previous")}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={15}
            />
            <span className="pagination-location">
              {t("page")} {currentPage} - {totalPages}
            </span>
            <CustomButton
              title={t("next")}
              variant="blank"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              icon={currentDirection === "ltr" ? nextIcon : previousIcon}
              iconSize={15}
              reverseIcon
            />
          </div>

          <div className="pagination-buttons">
            <span className="pagination-location">
              {t("totalNumber")}: {totalRecords}
              {console.log(totalRecords)}
            </span>
          </div>
        </section>

        {/* Edit customer Modal */}
        {customerModal && (
          <Modal
            title={isEditing ? t("editCustomer") : t("customer")}
            onClose={handleCancel}
            footer={
              <>
                {loadingModal ? <Loader /> : null}
                <section className="edit-user-actions">
                  {isEditing ? (
                    <>
                      <CustomButton
                        onClick={handleSaveCustomer}
                        title={t("confirm")}
                        icon={saveIcon}
                        iconSize={16}
                        disabled={loadingModal}
                      />
                      <CustomButton
                        title={t("cancel")}
                        onClick={handleCancel}
                        variant="blank"
                        icon={closeIcon}
                        iconSize={14}
                      />
                    </>
                  ) : (
                    <>
                      {userData?.userId ? (
                        <CustomButton
                          variant="blank"
                          title={t("edit")}
                          onClick={() => setIsEditing(true)}
                          icon={editIcon}
                          iconSize={18}
                        />
                      ) : null}
                    </>
                  )}
                </section>
              </>
            }
          >
            {error && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorBox>{errorMessage}</ErrorBox>
              </div>
            )}
            <div>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("customerName")}: </span>
                      <CustomInput
                        name="name"
                        value={customerFields.name || ""}
                        onChange={(e) => handleInputChange(e, "name")}
                        errorText={
                          errorMessage ? t("inputFieldCannotBeEmpty") : ""
                        }
                      />{" "}
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("customerName")}:</p>
                      <p variant="body1">{selectedCustomer?.name}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("email")}: </span>
                      <CustomInput
                        name="email"
                        value={customerFields.email || ""}
                        onChange={(e) => handleInputChange(e, "email")}
                      />{" "}
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("email")}:</p>
                      <p variant="body1">{selectedCustomer?.email}</p>
                      {console.log("email", selectedCustomer)}
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("phoneNumber")}: </span>
                      <CustomInput
                        name="phoneNumber"
                        value={customerFields.phoneNumber || ""}
                        onChange={(e) => handleInputChange(e, "phoneNumber")}
                      />{" "}
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("phoneNumber")}:</p>
                      <p variant="body1">{selectedCustomer?.phoneNumber}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  {isEditing ? (
                    <div className="edit-user-entity">
                      <span>{t("address")}: </span>
                      <CustomInput
                        name="address"
                        value={customerFields.address || ""}
                        onChange={(e) => handleInputChange(e, "address")}
                      />{" "}
                    </div>
                  ) : (
                    <div className="view-user-entity">
                      <p variant="body1">{t("address")}:</p>
                      <p variant="body1">{selectedCustomer?.address}</p>
                    </div>
                  )}
                </div>
              </section>
              <section>
                <div>
                  <div className="view-user-entity">
                    <p variant="body1">{t("usedCurrency")}:</p>
                    <p variant="body1">{selectedCustomer?.currency}</p>
                  </div>
                </div>
              </section>
            </div>
          </Modal>
        )}

        {/* Add Customer Modal */}
        {addCustomerModal && (
          <Modal
            title={t("createCustomer")}
            onClose={handleCancelAddCustomer}
            withDropdown
            footer={
              <>
                {loadingModal ? <Loader /> : null}
                <section className="edit-user-actions">
                  <CustomButton
                    title={t("confirm")}
                    onClick={handleCreateCustomer}
                    icon={saveIcon}
                    iconSize={16}
                    disabled={loadingModal}
                  />
                  <CustomButton
                    title={t("cancel")}
                    onClick={handleCancelAddCustomer}
                    variant="blank"
                    icon={closeIcon}
                    iconSize={14}
                  />
                </section>
              </>
            }
          >
            <form onSubmit={handleCreateCustomer}>
              <div>
                {error && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ErrorBox>{errorMessage}</ErrorBox>
                  </div>
                )}
                <div className="edit-user-entity">
                  <span>{t("customerName")}: </span>
                  <CustomInput
                    name="name"
                    value={newCustomerFields.name}
                    onChange={(e) => handleNewCustomerInputChange(e, "name")}
                    errorText={errorMessage ? t("inputFieldCannotBeEmpty") : ""}
                  />
                  <span>{t("email")}: </span>
                  <CustomInput
                    name="email"
                    value={newCustomerFields.email}
                    onChange={(e) => handleNewCustomerInputChange(e, "email")}
                  />
                  {console.log("email ", newCustomerFields.email)}
                  <span>{t("phoneNumber")}: </span>
                  <CustomInput
                    name="phoneNumber"
                    value={newCustomerFields.phoneNumber}
                    onChange={(e) =>
                      handleNewCustomerInputChange(e, "phoneNumber")
                    }
                  />
                  <span>{t("address")}: </span>
                  <CustomInput
                    name="address"
                    value={newCustomerFields.address}
                    onChange={(e) => handleNewCustomerInputChange(e, "address")}
                  />

                  <span>{t("usedCurrency")}: </span>
                  <Dropdown
                    options={currencies}
                    selected={supplierCurrency}
                    setSelected={setSupplierCurrency}
                    flex
                  />
                </div>
              </div>
            </form>
          </Modal>
        )}
      </div>
    </div>
  );
}
