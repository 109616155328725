import React, { useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CustomButton from "../components/CustomButton";
import Modal from "../components/Modal";
import CustomInput from "../components/CustomInput";
import classes from "../components/PDFUploader.module.css";
import uploadIcon from "../assets/upload_icon.png";
import addIcon from "../assets/add_icon.png";
import { useDocs } from "../utils/useDocs";
import AllDocumentsSlider from "../components/AllDocumentsSlider";
import saveIcon from "../assets/save_icon.png";
import classes1 from "../components/Modal.module.css";
import useLanguage from "../utils/useLanguage";

const AnalyzePDF = forwardRef((props, ref) => {
  const { handlePrepareDocs } = useDocs();
  const { t } = useTranslation("general");
  const [docsFields, setDocsFields] = useState({
    count: 1,
    max: 10,
  });
  const { currentDirection } = useLanguage();
  const [loadingModal, setLoadingModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [extractedData, setExtractedData] = useState({});
  const [pdfFile, setPdfFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [tempDocs, setTempDocs] = useState([]);
  const [documents, setDocuments] = useState([]);

  const token = localStorage.getItem("token");
  const userId = parseInt(localStorage.getItem("userId"), 10);
  const supplierId = parseInt(localStorage.getItem("supplierId"), 10);

  const handleCancelAddCustomer = () => setShowModal(false);

  const handleNewCustomerInputChange = (e, field) => {
    setExtractedData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const fieldLabels = {
    InvoiceDate: "invoiceDate",
    InvoiceNumber: "invoiceNumber",
    TotalValue: "Total Value",
    material: "Material",
    numberOfBoxes: "numberOfBoxes",
    totalInvoiceAmount: "Total Invoice Amount",
    unitPrice: "unitPrice",
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!pdfFile) {
      setUploadStatus("Please select a PDF file to upload.");
      return;
    }

    console.log("Selected file:", pdfFile); // Debugging line

    const formData = new FormData();
    formData.append("file", pdfFile);

    try {
      setUploadStatus("Uploading...");
      setLoadingModal(true);
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_URL}/extractPdf`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": supplierId,
          },
        }
      );

      console.log("Upload Response:", uploadResponse); // Debugging line

      if (uploadResponse.status === 200) {
        setUploadStatus("Upload successful.");
        setExtractedData(uploadResponse.data);
        setShowModal(true); // Show the modal after successful upload
      } else {
        setUploadStatus(`Unexpected response: ${uploadResponse.statusText}`);
      }
    } catch (error) {
      console.error("Error uploading PDF:", error); // Debugging line

      if (error.response) {
        setUploadStatus(
          `Server Error: ${
            error.response.data.message || error.response.statusText
          }`
        );
      } else if (error.request) {
        setUploadStatus("No response received from the server.");
      } else {
        setUploadStatus("Failed to upload the PDF. Please try again.");
      }
    }
  };

  const generateUploadInputs = () => {
    return Array.from({ length: docsFields.count }).map((_, index) => (
      <>
        <form
          onSubmit={handleUpload}
          className={classes.inputContainer}
          key={index}
        >
          <input
            accept="application/pdf, image/*"
            type="file"
            onChange={(e) => {
              setPdfFile(e.target.files[0]);
              handlePrepareDocs(
                e,
                `Invoice ${props?.invoiceNumber} - image ${index + 1}`
              );
              setTempDocs((prev) => [...prev, e.target.files[0]]);
            }}
            className={classes.fileInput}
            name="images"
            id={`invoiceDoc${index + 1}`}
          />
          <label htmlFor={`invoiceDoc${index + 1}`}>
            <p className={classes.customFileInput}>
              <img src={uploadIcon} alt={`Upload image ${index + 1}`} />
              <span>
                {t("uploadDocument")} {index + 1}
              </span>
            </p>
          </label>
          {tempDocs[index] && (
            <span className={classes.fileName}>
              {t("fileName")} : {tempDocs[index]?.name?.slice(0, 30)}
            </span>
          )}
          <button
            type="submit"
            className={classes.customFileInput}
            style={{ marginBottom: 30 }}
          >
            {t("analyze")}
          </button>
        </form>
      </>
    ));
  };

  return (
    <>
      <section className={classes.section}>
        <div className={classes.pdfUploaderSection}>
          <span>{t("uploadDocs")}: </span>
          <div className={classes.pdfUploaderSectionActions}>
            {generateUploadInputs()}
          </div>
        </div>

        {showModal && (
          <Modal
            title={t("analyzeData")}
            onClose={handleCancelAddCustomer}
            footer={
              <section className={classes1.actions}>
                <CustomButton
                  title={t("confirm")}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
                <CustomButton title={t("cancel")} variant="blank" />
              </section>
            }
          >
            <form className={classes1.flexableForm}>
              {Object.entries(extractedData).map(([key, value]) => (
                <div className={classes1.section}>
                  <div key={key} className={classes1.inputContainer}>
                    <span
                      className={
                        currentDirection === "rtl" ? classes.shortSpan : ""
                      }
                    >
                      {t(fieldLabels[key]) || t(key)}:
                    </span>

                    <CustomInput
                      flex={true}
                      name={key}
                      value={value || ""}
                      onChange={(e) => handleNewCustomerInputChange(e, key)}
                    />
                  </div>
                </div>
              ))}
            </form>
          </Modal>
        )}
      </section>

      {documents.length ? (
        <AllDocumentsSlider documents={documents} title="All Documents" />
      ) : null}
    </>
  );
});

export default AnalyzePDF;
