// src/pages/DashboardPage.js
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";

export default function DashboardPage() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const viewinvoiceManagementPage = () => {
    navigate("/invoiceManagement");
  };

  const viewIssuedAndImportedBooksPage = () => {
    navigate("/issuedAndImportedBooks");
  };

  return (
    <div className="control-panel-page" style={{ direction: currentDirection }}>
      <div className="control-panel-container">
        <div className="user-section">
          <div className="user">
            <span className="user-text">{`${t("user")}:`}</span>
            <span className="user-text">{`${localStorage.getItem(
              "username"
            )}`}</span>
          </div>
        </div>
        <h3>{t("controlPanel")}</h3>
        <div className="control-panel-actions">
          <CustomButton
            title={t("containersAndInvoices")}
            variant="blank"
            onClick={viewinvoiceManagementPage}
          />
          {/* <CustomButton
            title={t("importedAndExportedBooks")}
            variant="blank"
            onClick={viewIssuedAndImportedBooksPage}
          /> */}
          <CustomButton
            title={t("suppliers")}
            variant="blank"
            elementType={Link}
            to="/viewSuppliersPage"
          />
        </div>
      </div>
    </div>
  );
}
