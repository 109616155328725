import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import { useTranslation } from "react-i18next";
import BackToDashboard from "../../components/BackToDashboard";
import useLanguage from "../../utils/useLanguage";
import ErrorBox from "../../components/ErrorBox";
import Modal from "../../components/Modal";
import CustomButton from "../../components/CustomButton";
import classes from "./CustomersPage.module.css";
import classes1 from "../../components/PDFUploader.module.css";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";
import Loader from "../../components/Loader";
import uploadIcon from "../../assets/upload_icon.png";
import addIcon from "../../assets/add_icon.png";
import axios from "axios";
import { DataContext } from "../../utils/DataContext";
import PDFUploader from "../../components/PDFUploader";
import ErrorMessage from "../../components/ErrorMessage";

const MessagePage = () => {
  const location = useLocation();
  const { t } = useTranslation("general");
  const { selectedCustomers, filteredCustomers } = location.state || {};
  const { currentDirection } = useLanguage();
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const [invoiceDocs, setInvoiceDocs] = useState([]);

  const { data } = useContext(DataContext);
  const pdfUploaderRef = useRef(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const handleChildUploadDocs = (invoiceId) => {
    if (pdfUploaderRef.current) {
      pdfUploaderRef.current.handleUploadDocs(invoiceId);
    }
  };

  const isFormValid = notes.trim() !== "" || invoiceDocs.length > 0;

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const validImageFiles = newFiles.filter((file) =>
      file.type.startsWith("image/")
    );
    setFiles((prevFiles) => [...prevFiles, ...validImageFiles]);
  };

  const handlePlusClick = () => {
    fileInputRef.current.click();
  };

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = () => {
    if (notes.trim() === "" && invoiceDocs.length === 0) {
      setError(t("emptyFields")); // Show error when both fields are empty
      return;
    }
    setError(""); // Clear error if validation passes
    openModal();
  };

  const handleCloseModal = () => {
    openModal();
  };

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file));
    };
  }, [files]);

  if (
    !selectedCustomers ||
    !filteredCustomers ||
    !Array.isArray(filteredCustomers)
  ) {
    return (
      <div>
        No data received. Please go back and check the data being passed.
      </div>
    );
  }

  return (
    <div className="e-container">
      <div
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <div>
          <BackToDashboard
            title={t("back")}
            variant="blank-fit-content"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
          />
        </div>
        {error && (
          <div className={classes.errorBox}>
            <ErrorBox>{error}</ErrorBox>
          </div>
        )}
        <ul className={classes.ulStyle}>
          {filteredCustomers.map((name, index) => (
            <li key={index} className={classes.namesStyle}>
              {name}
            </li>
          ))}
        </ul>

        <div className={classes.layoutMsg}>
          <label>{t("titleMessage")}</label>
          <ErrorMessage>{error}</ErrorMessage>
          <CustomInput
            name="notes"
            type="textarea"
            placeholder={t("msg")}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <div className={classes.layoutBtns}>
            <PDFUploader
              ref={pdfUploaderRef}
              invoiceNumber={data?.invoiceNumber}
              invoiceDocs={invoiceDocs}
              setInvoiceDocs={setInvoiceDocs}
              mode="upload"
              documents={data?.documents}
              tempDocs={invoiceDocs}
              setTempDocs={setInvoiceDocs}
            />
          </div>

          {files.length > 0 && (
            <div className={classes.selectedFiles}>
              <div className={classes.previewImages}>
                {files.map((file, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className={classes.previewImage}
                    />
                    <button
                      aria-label={`Remove ${file.name}`}
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        background: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFiles((prevFiles) =>
                          prevFiles.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          {showModal && (
            <Modal
              title={t("contactCustomer")}
              onClose={handleCloseModal}
              footer={
                <>
                  {loadingModal ? <Loader /> : null}
                  <section
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "5px",
                    }}
                  >
                    <CustomButton
                      title={t("cancel")}
                      onClick={handleCloseModal}
                      variant="blank"
                      icon={closeIcon}
                      iconSize={14}
                    />
                    <CustomButton
                      title={t("confirm")}
                      icon={saveIcon}
                      iconSize={16}
                      disabled={loadingModal}
                    />
                  </section>
                </>
              }
            >
              <div>
                <h3 style={{ textAlign: "center" }}>{t("sureMsg")}</h3>
              </div>
            </Modal>
          )}
        </div>
        <div className={classes.alignCenter}>
          <CustomButton
            title={t("send")}
            iconSize={18}
            onClick={handleSubmit}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default MessagePage;
