import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";
import Dropdown from "./Dropdown";
import chartIcon from "../assets/chart_icon.png";
import supplierIcon from "../assets/supplier_icon.png";
import suppliersIcon from "../assets/suppliers_icon.png";
import { useContext } from "react";
import { DataContext } from "../utils/DataContext";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../utils/AuthContext";

export default function UserSection({ handleShowChart, page = "invoices" }) {
  const { t } = useTranslation("general");
  const navigate = useNavigate();

  const {
    suppliers,
    selectedSupplier,
    getSuppliers,
    handleShowSupplierModal,
    handleSelectSupplier,
  } = useContext(DataContext);

  const { userData } = useContext(UserAuth);

  return (
    <>
      <div className="user-section">
        <div className="user">
          <span className="user-text">{`${t("user")}:`}</span>
          <span className="user-text">{`${localStorage.getItem(
            "username"
          )}`}</span>
        </div>
        {/* {selectedSupplier?.id ? (
          <div className="o-row responsive-box">
            <CustomButton
              title={t("addSupplier")}
              onClick={handleShowSupplierModal}
              icon={supplierIcon}
              iconSize={30}
              minWidth={168}
              variant="blank"
            />
            <CustomButton
              title={t("viewSuppliers")}
              onClick={() => navigate("/viewSuppliersPage")}
              icon={suppliersIcon}
              iconSize={30}
              minWidth={168}
              variant="blank"
            />
            <CustomButton
              title={t("viewChart")}
              onClick={handleShowChart}
              icon={chartIcon}
              iconSize={30}
              minWidth={168}
              variant="blank"
            />
          </div>
        ) : null} */}
      </div>

      {selectedSupplier?.id ? (
        <div className="search-bar supplier-bar">
          <section className="col">
            <label>{t("supplier")}: </label>
            <Dropdown
              options={
                page === "invoices" || page === "containerPrices"
                  ? suppliers
                  : [{ id: "All", name: "All" }, ...suppliers]
              }
              selected={selectedSupplier}
              setSelected={handleSelectSupplier}
              page={page}
              getData={getSuppliers}
              // thick
            />
          </section>
        </div>
      ) : null}
    </>
  );
}
