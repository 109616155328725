import { Container } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "../components/Card";
import ErrorBox from "../components/ErrorBox";

export const BOX_TYPE = {
  CONFIRMATION: "confirmation",
  INFORMATION: "information",
};

export default function MissingPage() {
  const { t } = useTranslation("general");
  const location = useLocation();
  const { title } = location.state || {};

  return (
    <Container maxWidth="xs">
      <Card Element="div" cardTitle={t(title)} collapsable={false}>
        <ErrorBox>{t("pageNotFound")}</ErrorBox>
      </Card>
    </Container>
  );
}
