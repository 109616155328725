// src/pages/organization-pages/ShippedContainerCard.js
import React, { useState } from 'react';

const Card = ({ containerNumber, amount }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div style={styles.card} onClick={openModal}>
        <div style={styles.header}>Container Details</div>
        <div style={styles.content}>
          <p><strong>Container Number:</strong> {containerNumber}</p>
          <p><strong>Amount:</strong> ${amount}</p>
        </div>
      </div>

      {isModalOpen && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <h2>Container Details</h2>
            <p><strong>Container Number:</strong> {containerNumber}</p>
            <p><strong>Amount:</strong> ${amount}</p>
            <button style={styles.closeButton} onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    width: '250px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: 'transform 0.3s',
  },
  cardHovered: {
    transform: 'scale(1.05)',
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '12px',
    textAlign: 'center',
  },
  content: {
    fontSize: '14px',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  closeButton: {
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    padding: '10px',
    width: '100%',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};

export default Card;
