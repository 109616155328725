// src/pages/IssuedBooks.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../InvoicesManagement.scss";
import { Container, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";

import CustomCheckbox from "../../components/CustomCheckbox";
import CustomInput from "../../components/CustomInput";
import Dropdown from "../../components/Dropdown";
import CustomButton from "../../components/CustomButton";
import CustomLink from "../../components/CustomLink";

import InformationBox from "../../components/InformationBox";
import Modal from "../../components/Modal";
import classes from "../../components/Modal.module.css";

import containersIcon from "../../assets/container.png";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";
import addIconLight from "../../assets/add_icon_light.png";
import searchIcon from "../../assets/search_icon.png";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import printIcon from "../../assets/printer_icon.png";

import UserSection from "../../components/UserSection";

import PDFUploader from "../../components/PDFUploader";
import Loader from "../../components/Loader";

// Mock Data Setup
const suppliers = [
  { id: 1, name: "Supplier A", currency: "USD" },
  { id: 2, name: "Supplier B", currency: "EUR" },
];

const rows = [
  { title: "bookNumber", value: "bookNumber" },
  { title: "Date", value: "date" },
  { title: "sentTo", value: "sentTo" },
  { title: "receivedBookDate", value: "receivedBookDate" },
  { title: "receivedBookNumber", value: "receivedBookNumber" },
  { title: "subject", value: "subject" },
  { title: "bookImage", value: "bookImage" },
  { title: "notes", value: "notes" },
];

const data = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
  bookNumber: `BN-${i + 1000}`,
  bookDate: new Date().toISOString().split("T")[0],
  sentTo: `Recipient ${i + 1}`,
  subject: `Subject ${i + 1}`,
  notes: `Note for book ${i + 1}`,
  bookImage: `Image URL ${i + 1}`,
  receivedBookNumber: `BNR-${i + 2000}`,
  selected: false,
}));

const ImportedBooks = () => {
  const theUsername = localStorage.getItem("username");
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const [books, setBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTitle, setSelectedTitle] = useState(rows[0].value);
  const [selectAll, setSelectAll] = useState(false);
  const [dataList, setDataList] = useState(data);

  // * Pagination
  const [booksCurrentPage, setBooksCurrentPage] = useState(1);
  const [booksTotalPages, setBooksTotalPages] = useState(1);
  const [booksTotalRecords, setBooksTotalRecords] = useState(1);
  const [loading, setLoading] = useState(true);

  const [loadingModal, setLoadingModal] = useState("");

  // Added states for the integrated part
  const [showAddRowButton, setShowAddRowButton] = useState(true);
  const [showAddBookButton, setShowAddBookButton] = useState(true);
  const [showInputFields, setShowInputFields] = useState(false);
  const [selectedBooksStatus, setSelectedBooksStatus] = useState("active");

  const [showBookModal, setShowBookModal] = useState(false);

  const handleOpenBookModal = () => setShowBookModal(true);
  const handleCloseBookModal = () => setShowBookModal(false);

  const fetchImportedBooks = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      if (userId && token) {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/getExportedBooks`,
          {
            params: {
              page: booksCurrentPage,
              limit: 10,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              "User-Id": userId,
            },
          }
        );

        if (response.data) {
          setBooks(response.data.exportedBooks || []);
          setBooksTotalPages(response.data.totalPages || 1);
          setBooksTotalRecords(response.data.totalBooks || 0);
        }
      } else {
        console.error("User ID or token missing");
      }
    } catch (error) {
      console.error("Error fetching imported books:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImportedBooks();
  }, [booksCurrentPage]);

  const handleNextPage = () => {
    if (booksCurrentPage < booksTotalPages) {
      setBooksCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (booksCurrentPage > 1) {
      setBooksCurrentPage((prev) => prev - 1);
    }
  };

  const handleSearch = () => {
    const filteredBooks = books.filter((item) =>
      item[selectedTitle]
        ?.toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
    setBooks(filteredBooks);
  };

  const handleRowClick = (book) => {
    console.log("Book details:", book);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setBooks((prevBooks) =>
      prevBooks.map((item) => ({
        ...item,
        selected: !selectAll,
      }))
    );
  };

  const handlePrint = () => {
    console.log("Print action triggered");
  };

  const handleChangeStatus = (value) => setSelectedBooksStatus(value);

  const [newBook, setNewBook] = useState({
    bookNumber: "",
    date: "",
    sentTo: "",
    subject: "",
    notes: "",
  });

  const handleInputChange = (field, value) => {
    setNewBook((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCreateBook = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      console.error("Missing token or user ID.");
      return;
    }

    // Validate and sanitize input fields
    if (!newBook.bookNumber?.trim()) {
      console.error("Book number is required.");
      return;
    }

    try {
      setLoadingModal("createExportedBook");

      const payload = {
        bookNumber: newBook.bookNumber,
        date: newBook.date,
        sentTo: newBook.sentTo,
        subject: newBook.subject,
        notes: newBook.notes,
        creator: theUsername, // Assuming theUsername is needed
      };
      console.log("Payload:", payload);

      const response = await axios.post(
        "http://localhost:3005/createExportedBook",
        payload
      );
      console.log("Book created successfully:", response.data);

      if (response.status === 201) {
        console.log("Book created successfully:", response.data);
        setBooks((prevBooks) => [...prevBooks, response.data]); // Update books list
        handleCloseBookModal(); // Close the modal
      } else {
        console.error("Failed to create book:", response.data);
      }
    } catch (error) {
      console.error("Error creating book:", error);
    } finally {
      setLoadingModal("");
    }
  };

  return (
    <div className="e-container">
      <div
        className="view-users-page-container"
        style={{ direction: currentDirection }}
      >
        <div>
          <CustomButton
            title={t("back")}
            variant="blank"
            icon={currentDirection === "ltr" ? previousIcon : nextIcon}
            iconSize={14}
            onClick={() => navigate("/issuedAndImportedBooks")}
          />
        </div>
        <div className="control-panel-container">
          {loading ? (
            <Loader />
          ) : (
            <>
              <main className="main">
                <div className="invoice-table-container">
                  <div className="user-section">
                    <div className="user">
                      <span className="user-text">{`${t("user")}:`}</span>
                      <span className="user-text">{`${localStorage.getItem(
                        "username"
                      )}`}</span>
                    </div>
                  </div>
                  <h3>{t("issuedBooksManagement")}</h3>
                  {/* Filters */}
                  <div className="search-bar">
                    <section className="col">
                      <label>{t("search")}: </label>
                      <CustomInput
                        placeholder={t("search")}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        thick
                      />
                    </section>

                    <section className="col title-col">
                      <label>{t("searchTitle")}: </label>
                      <Dropdown
                        options={rows.map((row) => ({
                          ...row,
                          title: row.title,
                        }))}
                        selected={selectedTitle}
                        setSelected={(value) => setSelectedTitle(value)}
                        thick
                      />
                    </section>

                    <section className="col button-col">
                      <CustomButton
                        title={t("search")}
                        onClick={handleSearch}
                        icon={searchIcon}
                        iconSize={15}
                      />
                      <CustomButton
                        title={selectAll ? t("deselectAll") : t("selectAll")}
                        onClick={handleSelectAll}
                        thick
                      />
                    </section>
                  </div>
                  {/* Top Action Buttons */}
                  <div className="top-action-buttons responsive-box">
                    {showAddRowButton && (
                      <>
                        <div className="button-group">
                          {showAddBookButton && (
                            <CustomButton
                              title={t("createBook")}
                              onClick={handleOpenBookModal}
                              icon={addIconLight}
                              iconSize={20}
                            />
                          )}
                          {data.length !== 0 && !showInputFields && (
                            <CustomButton
                              title={t("print")}
                              onClick={handlePrint}
                              variant="gray"
                              icon={printIcon}
                              iconSize={18}
                            />
                          )}
                        </div>
                        <section className="col title-col">
                          <label>{t("booksStatus")}: </label>
                          <Dropdown
                            options={[
                              { title: "Active", value: "active" },
                              { title: "Archived", value: "archived" },
                            ]}
                            selected={selectedBooksStatus}
                            setSelected={handleChangeStatus}
                            thick
                          />
                        </section>
                      </>
                    )}
                  </div>

                  {books.length === 0 ? (
                    <InformationBox>{t("noBooksAvailable")}</InformationBox>
                  ) : (
                    <>
                      {/* Table */}
                      <section className="table-container">
                        <table className="i-table">
                          <thead className="table-head">
                            <tr>
                              <th className="bold-text">
                                <CustomCheckbox
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                  color="primary"
                                />
                              </th>
                              {rows.map((row, index) => (
                                <th
                                  key={index}
                                  className={`bold-text cell-title ${
                                    row.value
                                  } ${
                                    row.value !== "booknumber"
                                      ? "mobile-hider"
                                      : ""
                                  }`}
                                >
                                  {t(row.title)}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="table-body">
                            {books.map((book, index) => (
                              <tr
                                key={book.id}
                                className={`row ${
                                  book.selected ? "selected-row" : ""
                                }`}
                                onClick={() => handleRowClick(book)}
                              >
                                <td
                                  className={`cell ${
                                    currentDirection === "ltr"
                                      ? "rounded-first-cell"
                                      : "rounded-last-cell"
                                  }`}
                                >
                                  <CustomCheckbox
                                    checked={book.selected}
                                    onChange={() =>
                                      setBooks((prev) =>
                                        prev.map((item) =>
                                          item.id === book.id
                                            ? {
                                                ...item,
                                                selected: !item.selected,
                                              }
                                            : item
                                        )
                                      )
                                    }
                                  />
                                </td>
                                {rows.map((row) => (
                                  <td
                                    key={row.value}
                                    className={`cell mobile-hider ${
                                      row.value === "notes"
                                        ? currentDirection === "ltr"
                                          ? "rounded-last-cell"
                                          : "rounded-first-cell"
                                        : ""
                                    }`}
                                  >
                                    <p>{book[row.value]}</p>
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </section>

                      <section className="pagination">
                        <div className="pagination-buttons">
                          <CustomButton
                            variant="blank"
                            title={t("previous")}
                            onClick={handlePreviousPage}
                            disabled={booksCurrentPage === 1}
                            icon={
                              currentDirection === "ltr"
                                ? previousIcon
                                : nextIcon
                            }
                            iconSize={15}
                          />
                          <span className="pagination-location">
                            {t("page")}: {booksCurrentPage} - {booksTotalPages}
                          </span>
                          <CustomButton
                            title={t("next")}
                            variant="blank"
                            onClick={handleNextPage}
                            disabled={booksCurrentPage === booksTotalPages}
                            icon={
                              currentDirection === "ltr"
                                ? nextIcon
                                : previousIcon
                            }
                            iconSize={15}
                            reverseIcon
                          />
                        </div>
                        <div className="pagination-buttons">
                          <span className="pagination-location">
                            {t("totalRecords")}: {booksTotalRecords}
                          </span>
                        </div>
                      </section>
                    </>
                  )}
                </div>
              </main>

              {showBookModal && (
                <Modal
                  title="createBook"
                  onClose={handleCloseBookModal}
                  footer={
                    <>
                      {loadingModal === "createExportedBook" ? (
                        <Loader />
                      ) : null}

                      <section className={classes.actions}>
                        <CustomButton
                          onClick={handleCreateBook}
                          title={t("confirm")}
                          icon={saveIcon}
                          iconSize={16}
                          disabled={loadingModal === "createExportedBook"}
                        />
                        <CustomButton
                          title={t("cancel")}
                          onClick={handleCloseBookModal}
                          variant="blank"
                          icon={closeIcon}
                          iconSize={14}
                        />
                      </section>
                    </>
                  }
                >
                  <div className={classes.flexableForm}>
                    <section className={classes.section}>
                      <div className={classes.inputContainer}>
                        <span>{t("username")}: </span>
                        <CustomInput
                          name="creator"
                          value={theUsername} // Assuming this is predefined and read-only
                          flex={true}
                          readonly={true}
                        />
                      </div>
                    </section>
                    <section className={classes.section}>
                      <div className={classes.inputContainer}>
                        <span>{t("bookNumber")}: </span>
                        <CustomInput
                          name="bookNumber"
                          placeholder={t("enterBookNumber")}
                          value={newBook.bookNumber} // Bind to state
                          onChange={(e) =>
                            setNewBook((prev) => ({
                              ...prev,
                              bookNumber: e.target.value,
                            }))
                          }
                          flex={true}
                        />
                      </div>
                    </section>
                    <section className={classes.section}>
                      <div className={classes.inputContainer}>
                        <span>{t("Date")}: </span>
                        <CustomInput
                          name="bookDate"
                          type="date"
                          value={newBook.date} // Bind to state
                          onChange={(e) =>
                            setNewBook((prev) => ({
                              ...prev,
                              date: e.target.value,
                            }))
                          }
                          flex={true}
                        />
                      </div>
                    </section>
                    <section className={classes.section}>
                      <div className={classes.inputContainer}>
                        <span>{t("sentTo")}: </span>
                        <CustomInput
                          name="sentTo"
                          placeholder={t("enterRecipient")}
                          value={newBook.sentTo} // Bind to state
                          onChange={(e) =>
                            setNewBook((prev) => ({
                              ...prev,
                              sentTo: e.target.value,
                            }))
                          }
                          flex={true}
                        />
                      </div>
                    </section>
                    <section className={classes.section}>
                      <div className={classes.inputContainer}>
                        <span>{t("subject")}: </span>
                        <CustomInput
                          name="subject"
                          placeholder={t("enterSubject")}
                          value={newBook.subject} // Bind to state
                          onChange={(e) =>
                            setNewBook((prev) => ({
                              ...prev,
                              subject: e.target.value,
                            }))
                          }
                          flex={true}
                        />
                      </div>
                    </section>
                    <section className={classes.section}>
                      <div className={classes.inputContainer}>
                        <span>{t("Notes")}: </span>
                        <CustomInput
                          name="notes"
                          type="textarea"
                          placeholder={t("notes")}
                          value={newBook.notes} // Bind to state
                          onChange={(e) =>
                            setNewBook((prev) => ({
                              ...prev,
                              notes: e.target.value,
                            }))
                          }
                          flex={true}
                        />
                      </div>
                    </section>
                  </div>
                </Modal>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportedBooks;
