// src/pages/organization-pages/OrgShippedContainers.js
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "../InvoicesManagement.scss";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CustomCheckbox from "../../components/CustomCheckbox";
import CustomInput from "../../components/CustomInput";
import Dropdown from "../../components/Dropdown";
import CustomButton from "../../components/CustomButton";
import CustomLink from "../../components/CustomLink";

import { toMoney } from "../../utils/toMoney";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";
import InformationBox from "../../components/InformationBox";
import invoicesIcon from "../../assets/invoices.png";

import Modal from "../../components/Modal";
import classes from "../../components/Modal.module.css";
import ChartModal from "../../components/ChartModal";

import supplierIcon from "../../assets/supplier_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";
import addIconLight from "../../assets/add_icon_light.png";
import searchIcon from "../../assets/search_icon.png";
import printIcon from "../../assets/printer_icon.png";

import UserSection from "../../components/UserSection";
import { DataContext } from "../../utils/DataContext";
import { UserAuth } from "../../utils/AuthContext";
import BackToDashboard from "../../components/BackToDashboard";
import Loader from "../../components/Loader";
import { formatDate, toDate } from "../../utils/toDate";
import CreateShippedContainerModal from "./CreateShippedContainerModal";

const OrgShippedContainers = () => {
  const { t } = useTranslation("general");
  const navigate = useNavigate();

  const rows = [
    {
      title: "invoiceNumber",
      value: "invoiceNumber",
    },
    {
      title: "containerNumber",
      value: "containerNumber",
    },
    {
      title: "customer",
      value: "customer",
    },
    {
      title: "abstractAmountForCustomer",
      value: "abstractAmountForCustomer",
    },
    {
      title: "currency",
      value: "currency",
    },
    {
      title: "finalAmountForCustomer",
      value: "finalAmountForCustomer",
    },
    {
      title: "currency",
      value: "currency",
    },
    {
      title: "supplier",
      value: "supplier",
    },
    {
      title: "amountForSupplier",
      value: "amountForSupplier",
    },
    {
      title: "currency",
      value: "currency",
    },
    {
      title: "material",
      value: "material",
    },
    {
      title: "numberOfBoxes",
      value: "numberOfBoxes",
    },
    {
      title: "finalDestination",
      value: "finalDestination",
    },
    {
      title: "expectedArrivalDate",
      value: "expectedArrivalDate",
    },

    {
      title: "shipper",
      value: "shipper",
    },
    {
      title: "exitDate",
      value: "exitDate",
    },
    {
      title: "dateOfEntry",
      value: "dateOfEntry",
    },
    {
      title: "howWasPapersReceived",
      value: "howWasPapersReceived",
    },
    {
      title: "shippingDate",
      value: "shippingDate",
    },
    {
      title: "permitsReceivingDate",
      value: "permitsReceivingDate",
    },
    {
      title: "subCompany",
      value: "subCompany",
    },
    {
      title: "createdBy",
      value: "createdBy",
    },
    {
      title: "notes",
      value: "notes",
    },
  ];

  const [chartModalOpen, setChartModalOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState({
    title: t("containerNumber"),
    value: "containerNumber",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const { currentDirection } = useLanguage();

  const [showInputFields, setShowInputFields] = useState(false);
  const [showAddInvoiceButton, setShowAddInvoiceButton] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const { userData } = useContext(UserAuth);

  const {
    suppliers,
    getSuppliers,
    supplierModal,
    setSupplierModal,
    selectedSupplier,
    error,
    setError,
    loading,
    setLoading,
    shippedContainers,
    setShippedContainers,
    getCustomers,
    selectedCustomer,
    setSelectedCustomer,
    customers,
    currentBalance,
    setCurrentBalance,
    handleSelectSupplier,
    handleSelectCustomer,
    handleSaveSupplier,
    supplierFields,
    setSupplierFields,
    supplierCurrency,
    setSupplierCurrency,
    fetchShippedContainers,
    fetchArchivedShippedContainers,
    fetchShippedContainersBalance,
    selectedDate,
    setSelectedDate,
    currencies,
    networkError,
    setNetworkError,
    searchError,
    searchErrorMessage,
    handleShowSupplierModal,
    shippedContainersCurrentPage,
    shippedContainersCount,
    shippedContainersTotalPages,
    setShippedContainersCurrentPage,
    setShippedContainersCount,
    subCompanies,
    setSubCompanies,
    selectedSubCompany,
    setSelectedSubCompany,
    getSubCompanies,
    handleSelectSubCompany,
    selectedShippedContainersStatus,
    setSelectedShippedContainersStatus,
  } = useContext(DataContext);

  const [selectedCurrency, setSelectedCurrency] = useState({
    title: "USD",
    id: "USD",
  });

  const [tempCurrentBalance, setTempCurrentBalance] = useState({
    value: 0,
    containerId: 0,
  });

  const handlePrint = () => {
    // const selectedRows = data.filter((row) => row.selected);
    const selectedRows = [];
    console.log("Selected Row: ", selectedRows);
    if (selectedRows.length) {
      setError("");
      sessionStorage.setItem("selectedRows", JSON.stringify(selectedRows));
      window.open("/print", "_blank");
    } else {
      setError("noInvoiceToPrint");
    }
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
  };

  const getEmployees = async () => {
    const token =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");

    const organizationId = userData?.organizationId;

    try {
      setLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getEmployees`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": organizationId,
          },
        }
      );
      if (response?.status === 200) {
        setLoading(false);
      }

      if (response.data && response.data.employees) {
        const transformedResults = response.data.employees.map((emp) => {
          return {
            ...emp,
            name: emp?.username ? emp?.username : null,
          };
        });

        setEmployees(transformedResults);
      } else {
        console.error("Failed to get the users");
      }
    } catch (error) {
      setLoading(false);
      setNetworkError(true);
      console.error("Failed to get the users", error);
    }
  };

  useEffect(() => {
    fetchShippedContainers();

    // if (userData?.userType !== "organization") {
    //   setSelectedEmployee({ name: userData?.username, id: userData?.userId });
    // }

    console.log("shippedContainersCurrentPage: ", shippedContainersCurrentPage);
  }, [shippedContainersCurrentPage]);

  useEffect(() => {
    if (selectedSupplier?.id && selectedCustomer?.id) {
      // fetchShippedContainersBalance();
    }
  }, [selectedSupplier, selectedCustomer, shippedContainersCount]);

  const [selectAll, setSelectAll] = useState(false);
  const [searchBy, setSearchBy] = useState("");

  const handleShowChart = () => {
    setChartModalOpen(true);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // Update the selected status for all rows in the data array
    // setData((prevData) => {
    //   return prevData.map((row) => {
    //     return { ...row, selected: !selectAll };
    //   });
    // });
  };

  const handleSearch = async () =>
    await fetchShippedContainers(
      selectedSupplier?.id,
      selectedCustomer?.id,
      selectedTitle.value,
      searchQuery ||
        selectedDate ||
        selectedSubCompany?.id ||
        selectedEmployee?.id
    );

  const handleShowInputFields = () => {
    // setShowInputFields(true);
    navigate("/createShippedContainerPage");
  };

  const handleSearchType = async (type) => {
    console.log("type", type);
    setSelectedTitle(type);
    setSelectedDate("");
    // setSelectedSubCompany(null);
    // setSelectedEmployee(null);

    setSearchQuery("");
    setSearchBy("");
    if (
      type.value === "expectedArrivalDate" ||
      type.value === "exitDate" ||
      type.value === "dateOfEntry" ||
      type.value === "shippingDate" ||
      type.value === "permitsReceivingDate"
    ) {
      setSearchBy("date");
    }

    if (type.value === "subCompany") {
      setSearchBy("subCompany");
      await getSubCompanies();
    }

    if (type.value === "createdBy") {
      setSearchBy("createdBy");
      await getEmployees();
    }
  };

  const handleChangeStatus = (status) => {
    const { value } = status;
    setSelectedShippedContainersStatus(status);
    setShippedContainersCurrentPage(1);
    setSearchQuery("");

    if (value === "active") {
      setShowAddInvoiceButton(true);
      fetchShippedContainers();
    } else if (value === "archived") {
      setShowAddInvoiceButton(false);
      fetchArchivedShippedContainers(selectedSupplier?.id);
    }
  };

  const balanceStyle =
    currentBalance?.value >= 0 ? "positive-value" : "negative-value";

  return (
    <>
      <main className="e-container">
        <BackToDashboard
          title={t("back")}
          variant="blank"
          icon={currentDirection === "ltr" ? previousIcon : nextIcon}
          iconSize={14}
          to="/organization-dashboard"
        />

        <div className="invoice-table-container">
          <UserSection
            handleShowChart={handleShowChart}
            page="shippedContainers"
          />

          <div className="search-bar customer-bar">
            <section className="col">
              <label>{t("customer")}: </label>
              {selectedCustomer?.id ? (
                <Dropdown
                  options={[{ id: "All", name: "All" }, ...customers]}
                  selected={selectedCustomer}
                  setSelected={handleSelectCustomer}
                  errorText="Failed to get"
                  // thick
                  getData={getCustomers}
                />
              ) : (
                <span>{t("noCustomersFound")}</span>
              )}
            </section>
          </div>

          <div className="under-user-section">
            <div className="page-title">{t("shippedContainers")}</div>
            <div className="o-row responsive-box">
              <div className="navigation-button-container">
                <CustomButton
                  elementType={Link}
                  title={t("invoicesPage")}
                  to="/invoicesOfShippedContainers"
                  icon={invoicesIcon}
                />
              </div>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {!selectedSupplier?.id ? (
                <div className="full-w-button-container">
                  <div className="column-flex-box flex-center-center">
                    <InformationBox>
                      {t("noSuppliersAddFromUser")}
                    </InformationBox>
                    <CustomButton
                      title={t("goToSuppliersPage")}
                      minWidth={140}
                      variant="blank"
                      elementType={Link}
                      to={"/viewSuppliersPage"}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="top-action-buttons responsive-box">
                    <div className="button-group">
                      {showAddInvoiceButton ? (
                        <CustomButton
                          title={t("addContainer")}
                          onClick={handleShowInputFields}
                          icon={addIconLight}
                          iconSize={20}
                        />
                      ) : null}
                      {/* {data.length !== 0 && !showInputFields ? (
                          <CustomButton
                            title={t("print")}
                            onClick={handlePrint}
                            variant="gray"
                            icon={printIcon}
                            iconSize={18}
                          />
                        ) : null} */}
                    </div>
                    <section className="col title-col ">
                      <label>{t("invoicesStatus")}: </label>
                      <Dropdown
                        options={[
                          { title: "Active", value: "active" },
                          { title: "Archived", value: "archived" },
                        ]}
                        selected={selectedShippedContainersStatus}
                        setSelected={handleChangeStatus}
                        thick
                      />
                    </section>
                  </div>

                  {shippedContainers.length !== 0 ? (
                    <>
                      <div className="search-bar">
                        <section className="col">
                          <label>{t("search")}: </label>

                          {searchBy === "date" ? (
                            <DatePicker
                              selected={selectedDate}
                              onChange={(date) => {
                                setSelectedDate(
                                  date.toLocaleDateString("en-CA")
                                );
                              }}
                              dateFormat="yyyy-MM-dd"
                              placeholderText={t("searchByDate")}
                            />
                          ) : searchBy === "subCompany" ? (
                            <>
                              {subCompanies.length ? (
                                <Dropdown
                                  options={subCompanies}
                                  selected={selectedSubCompany}
                                  setSelected={handleSelectSubCompany}
                                  thick
                                />
                              ) : (
                                <span className="error-text">
                                  {t("noSubCompaniesFound")}
                                </span>
                              )}
                            </>
                          ) : searchBy === "createdBy" ? (
                            <>
                              <Dropdown
                                options={employees}
                                selected={selectedEmployee}
                                setSelected={handleSelectEmployee}
                                thick
                              />
                            </>
                          ) : (
                            <CustomInput
                              placeholder={t("search")}
                              value={searchQuery}
                              onChange={(e) => {
                                setSearchQuery(e.target.value);
                              }}
                              thick
                            />
                          )}
                        </section>

                        <section className="col title-col">
                          <label>{t("searchTitle")}: </label>
                          <Dropdown
                            options={rows?.filter(
                              (row) =>
                                row?.title !== "Customer" &&
                                row?.title !== "Supplier"
                            )}
                            selected={selectedTitle}
                            setSelected={handleSearchType}
                            thick
                          />
                        </section>
                        <section className="col button-col">
                          <CustomButton
                            title={t("search")}
                            onClick={handleSearch}
                            icon={searchIcon}
                            iconSize={15}
                          />
                        </section>
                      </div>

                      <section className="table-container">
                        <table className="i-table">
                          <thead className="table-head">
                            <tr>
                              <th className="bold-text">
                                {/* Select or deselect all */}
                                <CustomCheckbox
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                  color="primary"
                                />
                              </th>

                              {rows.map((row, index) => {
                                if (row?.title === "amountIn") {
                                  return (
                                    <th
                                      key={index}
                                      className={`bold-text cell-title ${
                                        row?.value
                                      } ${
                                        row?.value !== "containerNumber"
                                          ? "mobile-hider"
                                          : ""
                                      }`}
                                    >
                                      {t(row?.title) +
                                        " " +
                                        selectedSupplier?.currency}
                                    </th>
                                  );
                                } else {
                                  return (
                                    <th
                                      key={index}
                                      className={`bold-text cell-title ${
                                        row?.value
                                      } ${
                                        row?.value !== "containerNumber"
                                          ? "mobile-hider"
                                          : ""
                                      }`}
                                    >
                                      {t(row?.title)}
                                    </th>
                                  );
                                }
                              })}
                            </tr>
                          </thead>

                          <tbody className="table-body">
                            {/* These are the existing rows */}
                            {shippedContainers?.map(
                              (row, index) =>
                                !row?.canceled && (
                                  <tr key={index}>
                                    <td
                                      className={`cell ${
                                        currentDirection === "ltr"
                                          ? "rounded-first-cell"
                                          : "rounded-last-cell"
                                      }`}
                                    >
                                      <CustomCheckbox
                                        checked={row?.selected || false}
                                        onChange={(e) => {
                                          const updatedRow = [
                                            ...shippedContainers,
                                          ];
                                          updatedRow[index].selected =
                                            e.target.checked;
                                          setShippedContainers(updatedRow);
                                        }}
                                      />
                                    </td>

                                    <td className="cell invoice-number">
                                      <CustomLink
                                        to={`/shippedContainer/${row?.id}`}
                                      >
                                        <p className="link">
                                          {
                                            row?.multiContainersInvoice
                                              ?.invoiceNumber
                                          }
                                        </p>
                                        {/* {row?.updateCounter > 0 &&
                                              !row?.archived && (
                                                <p className="update-note">
                                                  {t("invoiceHasBeenUpdated")}
                                                </p>
                                              )} */}
                                      </CustomLink>
                                    </td>

                                    <td className="cell invoice-number">
                                      <CustomLink
                                        to={`/shippedContainer/${row?.id}`}
                                      >
                                        <p className="link">
                                          {row?.containerNumber}
                                        </p>
                                        {/* {row?.updateCounter > 0 &&
                                              !row?.archived && (
                                                <p className="update-note">
                                                  {t("invoiceHasBeenUpdated")}
                                                </p>
                                              )} */}
                                      </CustomLink>
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.customer?.name}
                                      {/* {customers.find(
                                            (customer) =>
                                              customer.id === row?.customerId
                                          )?.name || "-"} */}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {toMoney(
                                        row?.abstractAmountForCustomer
                                      ) || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {selectedCustomer?.currency || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {toMoney(row?.finalAmountForCustomer) ||
                                        "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {selectedCustomer?.currency || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.supplier?.name || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {toMoney(row?.amountForSupplier) || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {selectedSupplier?.currency || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.material || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.numberOfBoxes || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.finalDestination || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.expectedArrivalDate
                                        ? formatDate(row?.expectedArrivalDate)
                                        : "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.shipper || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.exitDate
                                        ? formatDate(row?.exitDate)
                                        : "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.dateOfEntry
                                        ? formatDate(row?.dateOfEntry)
                                        : "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.dateOfEntry
                                        ? formatDate(row?.dateOfEntry)
                                        : "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.shippingDate
                                        ? formatDate(row?.shippingDate)
                                        : "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.permitsReceivingDate
                                        ? formatDate(row?.permitsReceivingDate)
                                        : "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.subCompany?.name || "-"}
                                    </td>

                                    <td className="cell mobile-hider">
                                      {row?.user?.name ||
                                        row?.user?.username ||
                                        "-"}
                                    </td>

                                    <td
                                      className={`cell notes-cell mobile-hider ${
                                        currentDirection === "ltr"
                                          ? "rounded-last-cell"
                                          : "rounded-first-cell"
                                      }`}
                                    >
                                      {row?.notes || "-"}
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </section>

                      <section className="pagination">
                        <div className="pagination-buttons">
                          <CustomButton
                            variant="blank"
                            title={t("previous")}
                            onClick={() =>
                              setShippedContainersCurrentPage(
                                shippedContainersCurrentPage - 1
                              )
                            }
                            disabled={shippedContainersCurrentPage === 1}
                            icon={
                              currentDirection === "ltr"
                                ? previousIcon
                                : nextIcon
                            }
                            iconSize={15}
                          />
                          <span className="pagination-location">
                            {t("page")}: {shippedContainersCurrentPage} -{" "}
                            {shippedContainersTotalPages}
                          </span>
                          <CustomButton
                            title={t("next")}
                            variant="blank"
                            onClick={() =>
                              setShippedContainersCurrentPage(
                                shippedContainersCurrentPage + 1
                              )
                            }
                            disabled={
                              shippedContainersCurrentPage ===
                              shippedContainersTotalPages
                            }
                            icon={
                              currentDirection === "ltr"
                                ? nextIcon
                                : previousIcon
                            }
                            iconSize={15}
                            reverseIcon
                          />
                        </div>

                        <div className="pagination-buttons">
                          <span className="pagination-location">
                            {t("totalRecords")}: {shippedContainersCount}
                          </span>
                        </div>
                      </section>

                      {/* {selectedSupplier?.id && selectedCurrency?.id && (
                            <div className="balances">
                              <p className="balance">
                                <span>{t("customerCurrentBalance")}: </span>
                                <span className={balanceStyle}>
                                  {toMoney(currentBalance?.value)}{" "}
                                  {selectedSupplier?.currency}
                                </span>
                              </p>
                              <p className="balance">
                                <span>{t("supplierCurrentBalance")}: </span>
                                <span className={balanceStyle}>
                                  {toMoney(currentBalance?.value)}{" "}
                                  {selectedSupplier?.currency}
                                </span>
                              </p>
                            </div>
                          )} */}
                    </>
                  ) : (
                    <InformationBox>
                      {searchError ? t(searchErrorMessage) : t("noInvoices")}
                    </InformationBox>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </main>

      {/* Render the ChartModal component */}
      {/* <ChartModal
        open={chartModalOpen}
        onClose={handleCloseChart}
        data={data}
      /> */}

      {showInputFields && (
        <CreateShippedContainerModal setShowInputFields={setShowInputFields} />
      )}
    </>
  );
};

export default OrgShippedContainers;
