import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { DataContext } from "../../utils/DataContext";
import { UserAuth } from "../../utils/AuthContext";
import { toMoney } from "../../utils/toMoney";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";

import ErrorBox from "../../components/ErrorBox";
import Loader from "../../components/Loader";
import CustomInput from "../../components/CustomInput";
import Dropdown from "../../components/Dropdown";
import CustomButton from "../../components/CustomButton";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";
import addIcon from "../../assets/add_icon.png";

import { getCleanedInvoiceNumber } from "../../utils/getCleanedInvoiceNumber";
import Modal from "../../components/Modal";
import classes from "../../components/Modal.module.css";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import { sanitizeNumber } from "../../utils/sanitizeNumber";

const CreateShippedContainerModal = ({ setShowInputFields }) => {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const {
    suppliers,
    getSuppliers,
    setSupplierModal,
    selectedSupplier,
    error,
    setError,
    setShippedContainers,
    selectedCustomer,
    customers,
    handleSelectSupplier,
    handleSelectCustomer,
    setSupplierFields,
    setNetworkError,
    setShippedContainersCount,
    subCompanies,
    selectedSubCompany,
    handleSelectSubCompany,
    getSubCompanies,
    getCustomers,
  } = useContext(DataContext);

  const { userData } = useContext(UserAuth);

  const [howWasPapersReceivedStatus, setHowWasPapersReceivedStatus] = useState({
    title: "receivedByUs",
    value: "receivedByUs",
  });

  const [newRecord, setNewRecord] = useState({
    containerNumber: "",
    createdBy: "",
    expectedArrivalDate: new Date(),
    finalDestination: "",
    shipper: "",
    material: "",
    numberOfBoxes: 0,
    exitDate: new Date(),
    dateOfEntry: new Date(),
    shippingDate: new Date(),
    permitsReceivingDate: new Date(),
    amountForSupplier: "",
    abstractAmountForCustomer: "",
    finalAmountForCustomer: "",
    notes: "",
  });

  const [invoiceNumber, setInvoiceNumber] = useState(""); // store the invoice number globally
  const [received, setReceived] = useState(""); // store the received globally
  const [loadingModal, setLoadingModal] = useState(false);
  const [dublicatedContainerNumber, setDublicatedContainerNumber] =
    useState("");
  const [dublicatedInvoiceNumber, setDublicatedInvoiceNumber] = useState("");

  // Prevent entering numbers in input field
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const [page, setPage] = useState(1); // Track the current page
  const [inputs, setInputs] = useState([
    {
      containerNumber: "",
      createdBy: "",
      expectedArrivalDate: new Date(),
      finalDestination: "",
      shipper: "",
      material: "",
      numberOfBoxes: 0,
      exitDate: new Date(),
      dateOfEntry: new Date(),
      shippingDate: new Date(),
      permitsReceivingDate: new Date(),
      amountForSupplier: "",
      abstractAmountForCustomer: "",
      finalAmountForCustomer: "",
      notes: "",
    },
  ]); // Initialize with one input object

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        containerNumber: "",
        createdBy: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",
        shipper: "",
        material: "",
        numberOfBoxes: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      },
    ]);
    setPage(inputs.length + 1); // Move to the new page
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    // Convert 'received' and 'finalAmountForCustomer' to Float before setting them in the state
    const updatedValue =
      name === "finalAmountForCustomer" ? parseFloat(value) : value;

    const updatedInputs = [...inputs];
    updatedInputs[index][name] = updatedValue;
    setInputs(updatedInputs);
  };

  const handleInvoiceNumberChange = (e) => {
    const { value } = e.target;
    setInvoiceNumber(value); // Update the global invoice number
  };

  const handleReceivedChange = (e) => {
    const { value } = e.target;
    setReceived(value); // Update the global received value
  };

  const handleNext = () => {
    if (page < inputs.length) setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    const name = e.target?.name;

    const updatedInputs = [...inputs];
    updatedInputs[page - 1][name] = new Date(date);
    setInputs(updatedInputs);
  };

  const handleAddRow = async () => {
    try {
      const token = userData?.token;
      const userId = parseInt(userData.userId, 10);
  
      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }
  
      setLoadingModal(true);
  
      // Collect all container numbers into an array
      const containerNumbers = inputs.map(input => input.containerNumber);
  
      // Ensure that date fields are in the correct format
      const dateFields = {
        expectedArrivalDate: inputs[0]?.expectedArrivalDate?.toISOString().split("T")[0],
        exitDate: inputs[0]?.exitDate?.toISOString().split("T")[0],
        dateOfEntry: inputs[0]?.dateOfEntry?.toISOString().split("T")[0],
        shippingDate: inputs[0]?.shippingDate?.toISOString().split("T")[0],
        permitsReceivingDate: inputs[0]?.permitsReceivingDate?.toISOString().split("T")[0],
      };
  
      // Send the request to create or link invoice and container
      const newShippedContainer = await axios.post(
        `${process.env.REACT_APP_URL}/createShippedContainer`,
        {
          invoiceNumber,  
          received,
          containerNumbers,  // Send the array of container numbers
          supplierId: selectedSupplier?.id,
          customerId: selectedCustomer?.id,
          subCompanyId: selectedSubCompany?.id,
          subCompanyName: selectedSubCompany?.name,
          createdBy: userData?.userId,
          ...dateFields,  // Include the correctly formatted dates
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );
  
      // Handle response from the server
      if (newShippedContainer.status === 201) {
        const { message, invoice, container } = newShippedContainer.data;
  
        // Add the new or linked shipped container to the state
        setShippedContainers((prev) => [container, ...prev]);
  
        // Reset form data
        setInputs([
          {
            containerNumber: "",
            createdBy: "",
            expectedArrivalDate: new Date(),
            finalDestination: "",
            shipper: "",
            material: "",
            numberOfBoxes: 0,
            exitDate: new Date(),
            dateOfEntry: new Date(),
            shippingDate: new Date(),
            permitsReceivingDate: new Date(),
            amountForSupplier: "",
            abstractAmountForCustomer: "",
            finalAmountForCustomer: "",
            notes: "",
          },
        ]);
  
        setError(""); // Clear error
        setShippedContainersCount((prev) => prev + 1);
        setShowInputFields(false);
      } else {
        // Handle backend error
        console.error("Error from server:", newShippedContainer);
        const errorMessage = newShippedContainer.data.message;
        setError(errorMessage); // Set the error message in state to display it
        setNetworkError(true);
      }
    } catch (error) {
      console.error("Error adding new shippedContainer:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message; // Extract error message from backend response
        setError(errorMessage); // Set the error message in state to display it
      } else {
        // Handle unexpected errors (network errors, etc.)
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoadingModal(false);
    }
  };
  
  

  const handleCancel = () => {
    setShowInputFields(false);
    setInputs([
      {
        containerNumber: "",
        createdBy: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",
        shipper: "",
        material: "",
        numberOfBoxes: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      },
    ]);
  };

  return (
    <Modal
      title="addContainer"
      onClose={handleCancel}
      subHeaderChildren={
        <div className={classes.flexableForm}>
          {error && <ErrorBox>{error}</ErrorBox>}
          <section>
            <div className={classes.inputContainer}>
              <span>{t("supplier")}: </span>
              <Dropdown
                options={suppliers}
                selected={
                  selectedSupplier.id !== "All"
                    ? selectedSupplier
                    : suppliers[0]
                }
                setSelected={handleSelectSupplier}
                // thick
                flex
                page="shippedContainers"
                getData={getSuppliers}
                errorText={error}
              />
            </div>
          </section>
          <section>
            <div className={classes.inputContainer}>
              <span>{t("customer")}: </span>
              <div className={classes.invoiceNumberContainer}>
                {selectedCustomer?.id ? (
                  <Dropdown
                    options={customers}
                    selected={
                      selectedCustomer.id !== "All"
                        ? selectedCustomer
                        : customers[0]
                    }
                    getData={getCustomers}
                    setSelected={handleSelectCustomer}
                    //   thick
                    flex={true}
                    errorText={error}
                  />
                ) : (
                  <span>{t("noCustomersFound")}</span>
                )}
              </div>
            </div>
          </section>
          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.inputContainer}>
              <span>{t("invoiceNumber")}: </span>
              <div className={classes.invoiceNumberContainer}>
                <CustomInput
                  name="invoiceNumber"
                  value={invoiceNumber}
                  onChange={handleInvoiceNumberChange}
                  flex={true}
                />
              </div>
            </div>
          </section>
          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.inputContainer}>
              <span>{t("received")}: </span>
              <div className={classes.invoiceNumberContainer}>
                <CustomInput
                  name="received"
                  value={received}
                  onChange={handleReceivedChange}
                  flex={true}
                />
              </div>
            </div>
          </section>
          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.modalSubHeaderDivider}>
              <span className={classes.modalSubHeaderDividerFirstDiv}>
                {t("containerDetails")}:{" "}
              </span>
              <span className={classes.modalSubHeaderDividerSecondDiv}></span>
            </div>
          </section>
        </div>
      }
      beforeFooterChildren={
        <div className={classes.beforeFooterContainer}>
          <div className={classes.beforeFooterNextPrevious}>
            <CustomButton
              variant="blank"
              title={t("previous")}
              onClick={handlePrevious}
              disabled={page === 1}
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={15}
            />
            <CustomButton
              title={t("next")}
              variant="blank"
              onClick={handleNext}
              disabled={page === inputs.length}
              icon={currentDirection === "ltr" ? nextIcon : previousIcon}
              iconSize={15}
              reverseIcon
            />
          </div>
          <div className={classes.beforeFooterChildren}>
            <CustomButton
              onClick={handleAddInput}
              variant="blank"
              title={t("addAnotherContainer")}
              icon={addIcon}
              iconSize={16}
              disabled={loadingModal}
            />
          </div>
        </div>
      }
      footer={
        <>
          {loadingModal ? <Loader /> : null}
          <section className={classes.actions}>
            <CustomButton
              onClick={handleAddRow}
              title={t("confirm")}
              icon={saveIcon}
              iconSize={16}
              disabled={loadingModal}
            />
            <CustomButton
              title={t("cancel")}
              onClick={handleCancel}
              variant="blank"
              icon={closeIcon}
              iconSize={14}
            />
          </section>
        </>
      }
    >
      <div
        className={`${classes.flexableForm} ${classes.flexableFormWithPadding}`}
      >
        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("containerNumber")}: </span>
            <div className={classes.invoiceNumberContainer}>
              <CustomInput
                name="containerNumber"
                value={inputs[page - 1]?.containerNumber}
                onChange={(e) => handleInputChange(e, page - 1)}
                flex={true}
              />
            </div>
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("amountForSupplier")}: </span>
            <CustomInput
              name="amountForSupplier"
              value={inputs[page - 1]?.amountForSupplier}
              onChange={(e) => handleInputChange(e, page - 1)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("expectedArrivalDate")}: </span>
            <CustomInput
              name="expectedArrivalDate"
              type="date"
              flex={true}
              value={
                inputs[page - 1]?.expectedArrivalDate
                  .toISOString()
                  .split("T")[0]
              }
              onChange={handleDateChange}
            />
          </div>
        </section>

        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("finalDestination")}: </span>
            <CustomInput
              name="finalDestination"
              value={inputs[page - 1]?.finalDestination}
              onChange={(e) => handleInputChange(e, page - 1)}
              flex={true}
            />
          </div>
        </section>

        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("material")}: </span>
            <CustomInput
              name="material"
              value={inputs[page - 1]?.material}
              onChange={(e) => handleInputChange(e, page - 1)}
              flex={true}
            />
          </div>
        </section>

        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("numberOfBoxes")}: </span>
            <CustomInput
              name="numberOfBoxes"
              value={inputs[page - 1]?.numberOfBoxes || ""}
              onChange={(e) => handleInputChange(e, page - 1)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>

        <section className={`${classes.section} ${classes.invoiceNumber}`}>
          <div className={classes.inputContainer}>
            <span>{t("shipper")}: </span>
            <CustomInput
              name="shipper"
              value={inputs[page - 1]?.shipper}
              onChange={(e) => handleInputChange(e, page - 1)}
              flex={true}
            />
          </div>
        </section>
        {/* Shipping Date */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("shippingDate")}: </span>
            <CustomInput
              name="shippingDate"
              type="date"
              flex={true}
              value={
                inputs[page - 1]?.shippingDate
                  ? inputs[page - 1].shippingDate.toISOString().split("T")[0]
                  : ""
              }
              onChange={handleDateChange}
            />
          </div>
        </section>

        {/* Sub Company */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("subCompany")}: </span>
            <Dropdown
              options={subCompanies}
              selected={selectedSubCompany}
              setSelected={handleSelectSubCompany}
              flex={true}
              getData={getSubCompanies}
            />
          </div>
        </section>

        {/* Exit Date */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("exitDate")}: </span>
            <CustomInput
              name="exitDate"
              type="date"
              flex={true}
              value={
                inputs[page - 1]?.exitDate
                  ? inputs[page - 1].exitDate.toISOString().split("T")[0]
                  : ""
              }
              onChange={handleDateChange}
            />
          </div>
        </section>

        {/* Abstract Amount For Customer */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("abstractAmountForCustomer")}: </span>
            <CustomInput
              name="abstractAmountForCustomer"
              value={inputs[page - 1]?.abstractAmountForCustomer}
              onChange={(e) => handleInputChange(e, page - 1)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>

        {/* Currency */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("currency")}: </span>
            <CustomInput
              name="currency"
              value={selectedCustomer?.currency}
              flex={true}
              readonly={true}
            />
          </div>
        </section>

        {/* Final Amount For Customer */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("finalAmountForCustomer")}: </span>
            <CustomInput
              name="finalAmountForCustomer"
              value={inputs[page - 1]?.finalAmountForCustomer}
              onChange={(e) => handleInputChange(e, page - 1)}
              onKeyDown={(e) => handleKeyDown(e)}
              type="money"
              flex={true}
            />
          </div>
        </section>

        {/* Date of Entry */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("dateOfEntry")}: </span>
            <CustomInput
              name="dateOfEntry"
              type="date"
              flex={true}
              value={
                inputs[page - 1]?.dateOfEntry
                  ? inputs[page - 1].dateOfEntry.toISOString().split("T")[0]
                  : ""
              }
              onChange={handleDateChange}
            />
          </div>
        </section>

        {/* How Was Papers Received */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("howWasPapersReceived")}: </span>
            <Dropdown
              options={[
                { title: "receivedByUs", value: "receivedByUs" },
                { title: "receivedByCustomer", value: "receivedByCustomer" },
              ]}
              selected={inputs[page - 1]?.howWasPapersReceived || {}}
              setSelected={(status) =>
                handleInputChange(
                  { target: { name: "howWasPapersReceived", value: status } },
                  page - 1
                )
              }
              flex
            />
          </div>
        </section>

        {/* Permits Receiving Date */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
            <span>{t("permitsReceivingDate")}: </span>
            <CustomInput
              name="permitsReceivingDate"
              type="date"
              flex={true}
              value={
                inputs[page - 1]?.permitsReceivingDate
                  ? inputs[page - 1].permitsReceivingDate
                      .toISOString()
                      .split("T")[0]
                  : ""
              }
              onChange={handleDateChange}
            />
          </div>
        </section>

        {/* Notes */}
        <section className={classes.section}>
          <div className={classes.inputContainer}>
          <span className={classes.notesClass}>{t("Notes")}: </span>
            <CustomInput
              name="notes"
              type="textarea"
              value={inputs[page - 1]?.notes || ""}
              onChange={(e) => handleInputChange(e, page - 1)}
              flex={true}
            />
          </div>
        </section>

        {/* <PDFUploader
            ref={pdfUploaderRef}
            invoiceNumber={data?.invoiceNumber}
            invoiceDocs={invoiceDocs}
            setInvoiceDocs={setInvoiceDocs}
            mode="upload"
            documents={data?.documents}
            tempDocs={invoiceDocs}
            setTempDocs={setInvoiceDocs}
        /> */}
      </div>
    </Modal>
  );
};

export default CreateShippedContainerModal;
