import { useContext, useEffect, useRef, useState } from "react";

import classes from "./ViewInvoice.module.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import axios from "axios";

// * Custom utils
import { toMoney } from "../../utils/toMoney";
import { getCleanedInvoiceNumber } from "../../utils/getCleanedInvoiceNumber";

import CustomInput from "../../components/CustomInput";

import printIcon from "../../assets/printer_icon.png";
import archiveIcon from "../../assets/archive_icon.png";
import editIcon from "../../assets/edit_icon.png";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";

// * Date Picker

import { DataContext } from "../../utils/DataContext";
import PDFUploader from "../../components/PDFUploader";
import CustomButton from "../../components/CustomButton";
import useLanguage from "../../utils/useLanguage";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import { formatDate, toDate } from "../../utils/toDate";
import { UserAuth } from "../../utils/AuthContext";

export default function ViewShippedContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation("general");
  const { id } = useParams();
  const { currentDirection } = useLanguage();
  const { userData } = useContext(UserAuth);
  const { selectedSupplier, fetchInvoices } = useContext(DataContext);

  const pdfUploaderRef = useRef(null);

  const [data, setData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [error, setError] = useState("");
  const { currentBalance, setCurrentBalance, setLoading, shippedContainers } =
    useContext(DataContext);
  const [tempCurrentBalance, setTempCurrentBalance] = useState({
    value: 0,
    containerId: 0,
  });
  const [newRecord, setNewRecord] = useState({});
  const [invoiceDocs, setInvoiceDocs] = useState([]);
  const [tempDocs, setTempDocs] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/getShippedContainer/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.status === 200) {
        setLoading(false);
      }

      setData(response?.data?.shippedContainer);
      setInvoiceDocs(response?.data?.documents);


      setNewRecord(response?.data?.shippedContainer);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    // setNewRecord(data[index]);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setError("");
    setNewRecord(data);
    setTempDocs([]);
  };

  // Prevent entering numbers in input field
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleConfirmEdit = async () => {
    const token = userData?.token;
    const organizationId = userData?.organizationId;

    try {
      setLoadingModal(true);
      // console.log("new record", newRecord);
      const updateShippedContainer = await axios.put(
        `${process.env.REACT_APP_URL}/updateShippedContainer`,
        {
          // * Reduce payload size => Useless data
          amountForCustomer: newRecord?.amountForCustomer,
          containerNumber: newRecord?.containerNumber,
          exitDate: newRecord?.exitDate,
          expectedArrivalDate: newRecord?.expectedArrivalDate,
          finalDestination: newRecord?.finalDestination,
          shippedContainerId: newRecord?.id,
          material: newRecord?.material,
          notes: newRecord?.notes,
          numberOfBoxes: parseInt(newRecord?.numberOfBoxes),
          permitsReceivingDate: newRecord?.permitsReceivingDate,
          shipper: newRecord?.shipper,
          shippingDate: newRecord?.shippingDate,
          amountForSupplier: newRecord?.amountForSupplier,
          abstractAmountForCustomer: newRecord?.abstractAmountForCustomer,
          finalAmountForCustomer: newRecord?.finalAmountForCustomer,
          updateCounter: newRecord?.updateCounter,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "organization-Id": organizationId,
          },
        }
      );

      // If no update occurred, log an error
      if (!updateShippedContainer) {
        console.error(
          `updatedCurrentBalance.error`,
          updateShippedContainer?.error
        );
      }

      // Update the record and data with the new invoice information returned from the backend
      setNewRecord(updateShippedContainer?.data?.updatedShippedContainer);
      setData(updateShippedContainer?.data?.updatedShippedContainer);
      setLoadingModal(false);
    } catch (error) {
      // Log and display any errors that occur during the update process
      console.error("Error updating invoice:", error);
      setError("Error updating invoice.");
    } finally {
      // Fetch the updated invoices list after the edit completes
      fetchInvoices(selectedSupplier?.id);
      setLoadingModal(false);
    }

    // Exit the edit mode after confirming the changes
    setEditMode(false);
  };

  const handleCloseArchiveModal = () => setShowArchiveModal(false);

  const handleArchive = async () => {
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    // console.log("Invoice Data", data);

    try {
      const archivedInvoice = await axios.delete(
        `${process.env.REACT_APP_URL}/archiveShippedContainer/${data?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
            "Supplier-Id": data?.supplierId,
          },
        }
      );

      if (!archivedInvoice?.data) {
        console.error(`archivedInvoice.error`, archivedInvoice.error);
      } else {
        // console.log("archivedInvoice?.data", archivedInvoice?.data);
        setCurrentBalance((prev) => ({
          ...prev,
          value: archivedInvoice?.data?.newBalance?.value,
        }));
      }

      navigate(`/orgShippedContainers`);
    } catch (error) {
      console.error("Error updating invoice:", error);
      setError("Error updating invoice.");
    } finally {
      fetchInvoices(selectedSupplier?.id);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // console.log("Name", name, "Value", value);

    setError("");
    setNewRecord((prevRow) => {
      let updatedRow = { ...prevRow, [name]: value };

      // if (name === "amountInSupCur") {
      //   if (value) {
      //     setTempCurrentBalance((prev) => ({
      //       ...prev,
      //       value: currentBalance.value + parseFloat(sanitizeNumber(value)),
      //     }));
      //   } else {
      //     setTempCurrentBalance((prev) => ({
      //       ...prev,
      //       value: parseFloat(sanitizeNumber(currentBalance.value)),
      //     }));
      //   }
      // }
      return updatedRow;
    });
  };

  const handleDateChange = (e) => {
    const fieldName = e.target.name;
    const date = e.target.value;
    setNewRecord((prevRecord) => ({
      ...prevRecord,
      [fieldName]: new Date(date),
    }));
  };

  return (
    <>
      <main className={classes.viewInvoicePage}>
        <div className="e-container">
          <div className="user-logout">
            <CustomButton
              title={t("back")}
              variant="blank"
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={14}
              onClick={() => navigate("/orgShippedContainers")}
            />

            <div className={classes.user} style={{ marginTop: "25px" }}>
              <p className={classes.userTitle}>{`${t("user")}: `}</p>
              <p className={classes.userName}>
                {` ${localStorage.getItem("username")}`}
              </p>
            </div>
          </div>

          {data?.archived && (
            <div className="error-message">{t("archivedInvoice")}</div>
          )}

          <section className={classes.section}>
            <h3 className={classes.invoiceDetailsTitle}>
              {t("containerDetails")}
            </h3>
          </section>
          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("createdBy")}: </span>
              <span className={`${classes.boldText} ${classes.breakWord}`}>
                {data?.user?.username || " - "}
              </span>
            </div>
          </section>

          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("invoiceNumber")}: </span>
              {editMode ? (
                <>
                  <CustomInput
                    name="invoiceNumber"
                    value={getCleanedInvoiceNumber(
                      newRecord?.multiContainersInvoice?.invoiceNumber
                    )}
                    onChange={() => null}
                    disabled
                    readOnly
                  />
                </>
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.multiContainersInvoice?.invoiceNumber || " - "}
                </span>
              )}
            </div>
          </section>

          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("containerNumber")}: </span>
              {editMode ? (
                <>
                  <CustomInput
                    name="containerNumber"
                    value={getCleanedInvoiceNumber(newRecord?.containerNumber)}
                    onChange={() => null}
                    disabled
                    readOnly
                  />
                </>
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {data?.containerNumber || " - "}
                </span>
              )}
            </div>
          </section>

          <section className={classes.sectionSpecial}>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("supplier")}: </span>
              <span
                className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
              >
                {data?.supplier?.name || " - "}
              </span>
            </div>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("customer")}: </span>
              <span
                className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
              >
                {data?.customer?.name || " - "}
              </span>
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>
                {t("searchBySubCompany")}:{" "}
              </span>
              <span className={`${classes.boldText} ${classes.breakWord}`}>
                {data?.subCompany?.name || " - "}
              </span>
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>
                {t("expectedArrivalDate")}:
              </span>
              {editMode ? (
                <CustomInput
                  type="date"
                  name="expectedArrivalDate"
                  value={
                    newRecord?.expectedArrivalDate
                      ? formatDate(newRecord?.expectedArrivalDate)
                      : "Invalid Date"
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {formatDate(data?.expectedArrivalDate) || "Invalid Date"}
                </span>
              )}
            </div>
          </section>

          <section className={classes.sectionSpecial}>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>
                {t("finalDestination")}:{" "}
              </span>
              {editMode ? (
                <CustomInput
                  name="finalDestination"
                  value={newRecord?.finalDestination}
                  // onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleInputChange}
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {data?.finalDestination || " - "}
                </span>
              )}
            </div>

            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("Shipper")}: </span>
              {editMode ? (
                <CustomInput
                  name="shipper"
                  value={newRecord?.shipper}
                  onChange={handleInputChange}
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {data?.shipper || " - "}
                </span>
              )}
            </div>
          </section>

          <section className={classes.sectionSpecial}>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("Material")}: </span>
              {editMode ? (
                <CustomInput
                  name="material"
                  value={newRecord?.material}
                  onChange={handleInputChange}
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {data?.material || " - "}
                </span>
              )}
            </div>

            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("numberOfBoxes")}: </span>
              {editMode ? (
                <CustomInput
                  name="numberOfBoxes"
                  value={newRecord?.numberOfBoxes}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleInputChange}
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {data?.numberOfBoxes || " - "}
                </span>
              )}
            </div>
          </section>
          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>
                {t("amountForSupplier") + " :"}
              </span>
              {editMode ? (
                <CustomInput
                  name="amountForSupplier"
                  value={newRecord?.amountForSupplier}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleInputChange}
                  type="money"
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.amountForSupplier) || " - "}
                </span>
              )}
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>
                {t("abstractAmountForCustomer") + " :"}
              </span>
              {editMode ? (
                <CustomInput
                  name="abstractAmountForCustomer"
                  value={newRecord?.abstractAmountForCustomer}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleInputChange}
                  type="money"
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.abstractAmountForCustomer) || " - "}
                </span>
              )}
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>
                {t("finalAmountForCustomer") + " :"}
              </span>
              {editMode ? (
                <CustomInput
                  name="finalAmountForCustomer"
                  value={newRecord?.finalAmountForCustomer}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleInputChange}
                  type="money"
                />
              ) : (
                <span className={`${classes.boldText} ${classes.breakWord}`}>
                  {toMoney(data?.finalAmountForCustomer)}
                </span>
              )}
            </div>
          </section>
          <section className={classes.sectionSpecial}>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("exitDate")}: </span>
              {editMode ? (
                <CustomInput
                  type="date"
                  name="exitDate"
                  value={
                    newRecord?.exitDate
                      ? formatDate(newRecord?.exitDate)
                      : "Invalid Date"
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {formatDate(data?.exitDate) || " - "}
                </span>
              )}
            </div>
          </section>

          <section className={classes.sectionSpecial}>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("dateOfEntry")}: </span>
              {editMode ? (
                <CustomInput
                  type="date"
                  name="dateOfEntry"
                  value={
                    newRecord?.dateOfEntry
                      ? formatDate(newRecord?.dateOfEntry)
                      : "Invalid Date"
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {formatDate(data?.dateOfEntry) || " - "}
                </span>
              )}
            </div>
          </section>
          <section className={classes.sectionSpecial}>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>{t("shippingDate")}: </span>
              {editMode ? (
                <CustomInput
                  type="date"
                  name="shippingDate"
                  value={
                    newRecord?.shippingDate
                      ? formatDate(newRecord?.shippingDate)
                      : "Invalid Date"
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {formatDate(data?.shippingDate) || " - "}
                </span>
              )}
            </div>
          </section>
          <section className={classes.sectionSpecial}>
            <div className={classes.sectionSpecialCol}>
              <span className={classes.colTitle}>
                {t("permitsReceivingDate")}:
              </span>
              {editMode ? (
                <CustomInput
                  type="date"
                  name="permitsReceivingDate"
                  value={
                    newRecord?.permitsReceivingDate
                      ? formatDate(newRecord?.permitsReceivingDate)
                      : "Invalid Date"
                  }
                  onChange={(date) => handleDateChange(date)}
                />
              ) : (
                <span
                  className={`${classes.sectionSpecialColText} ${classes.breakWord}`}
                >
                  {formatDate(data?.permitsReceivingDate)}
                </span>
              )}
            </div>
          </section>

          <section className={classes.section}>
            <div className={classes.col}>
              <span className={classes.colTitle}>{t("notes")}: </span>
              {editMode ? (
                <CustomInput
                  name="notes"
                  value={newRecord?.notes}
                  onChange={handleInputChange}
                />
              ) : (
                <span className={`${classes.breakWord}`}>{data?.notes}</span>
              )}
            </div>
          </section>

          <PDFUploader
            ref={pdfUploaderRef}
            invoiceNumber={shippedContainers?.invoiceNumber}
            invoiceDocs={Array.isArray(invoiceDocs) ? invoiceDocs : []}
            setInvoiceDocs={setInvoiceDocs}
            mode={!editMode && "show"}
            documents={Array.isArray(invoiceDocs) ? invoiceDocs : []}
            tempDocs={Array.isArray(tempDocs) ? tempDocs : []}
            setTempDocs={setTempDocs}
          />


          {loadingModal ? <Loader /> : null}
          <section className={classes.actions}>
            {editMode ? (
              <>
                <CustomButton
                  title={t("cancel")}
                  onClick={cancelEdit}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
                <CustomButton
                  title={t("confirm")}
                  onClick={handleConfirmEdit}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
              </>
            ) : (
              <>
                <CustomButton
                  variant="blank"
                  title={t("edit")}
                  onClick={handleEdit}
                  icon={editIcon}
                  iconSize={18}
                />
                {setEditMode ? (
                  <>
                    <CustomButton
                      title={t("print")}
                      onClick={handlePrint}
                      variant="gray"
                      icon={printIcon}
                      iconSize={18}
                    />
                    {!data?.archived && (
                      <CustomButton
                        title={t("archive")}
                        variant="red"
                        icon={archiveIcon}
                        iconSize={20}
                        onClick={() => setShowArchiveModal(true)}
                      />
                    )}
                  </>
                ) : null}
              </>
            )}
          </section>
        </div>
      </main>

      {showArchiveModal && (
        <Modal
          title={t("archiveInvoice")}
          onClose={handleCloseArchiveModal}
          footer={
            <>
              {loadingModal ? <Loader /> : null}
              <section className={classes.actions}>
                <CustomButton
                  onClick={handleArchive}
                  title={t("confirm")}
                  icon={saveIcon}
                  iconSize={16}
                  disabled={loadingModal}
                />
                <CustomButton
                  title={t("cancel")}
                  onClick={handleCloseArchiveModal}
                  variant="blank"
                  icon={closeIcon}
                  iconSize={14}
                />
              </section>
            </>
          }
        >
          <div className={classes.archiveModalBody}>
            <h3 className={classes.archiveModalBodyTitle}>{t("areYouSure")}</h3>
            <p className={classes.archiveModalInvoiceNumber}>
              {data?.invoiceNumber}
            </p>
          </div>
        </Modal>
      )}
    </>
  );
}
