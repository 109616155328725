// src/components/Dropdown.js
import React, { useState, useRef, useEffect } from "react";
import classes from "./Dropdown.module.css";
import arrowIcon from "../assets/down-arrow.png";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { req_Status } from "../utils/enums";

const Dropdown = ({
  options,
  selected,
  setSelected,
  thick,
  flex,
  errorText,
  onChange,
  page = "invoices",
  getData = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const isFirstClick = useRef(true);
  const { t } = useTranslation("general");
  const [loading, setLoading] = useState({ status: req_Status.NULL });

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = async () => {
    setIsOpen(!isOpen);

    if (getData && isFirstClick.current) {
      setLoading((prev) => ({ ...prev, status: req_Status.PENDING }));
      const req = await getData();
      isFirstClick.current = false;

      if (req?.status === 200) {
        setLoading((prev) => ({ ...prev, status: req_Status.SUCCESS }));
      } else {
        setLoading((prev) => ({ ...prev, status: req_Status.FAILED }));
      }
    }
  };

  const handleItemClick = (item) => {
    setSelected(item, page);
    if (onChange) {
      onChange(item);
    }
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      toggleDropdown();
    }
  };

  const showError = !selected && errorText;

  return (
    <div
      className={`${classes.dropdown} ${flex ? classes.fWidthDropDown : ""}`}
      ref={dropdownRef}
    >
      {showError && <div className="error-message">{t(errorText)}</div>}
      <div
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        tabIndex={0} // Make the dropdown toggle focusable
        className={`${classes.dropdownToggle} ${isOpen ? classes.opened : ""} ${
          thick ? classes.thick : ""
        }`}
      >
        {options && typeof selected === "object" && selected !== null
          ? t(selected?.title) || selected?.name
          : null}
        <img
          src={arrowIcon}
          alt="Dropdown arrow"
          className={`${classes.arrowIcon} ${isOpen ? classes.rotate180 : ""}`}
        />
      </div>
      {isOpen && (
        <>
          {/* <div className={classes.overlay} onClick={toggleDropdown}></div> */}
          <ul className={classes.dropdownMenu}>
            {Array.isArray(options) &&
              options.map((option, index) => (
                <li
                  key={index}
                  className={classes.dropdownItem}
                  onClick={() => handleItemClick(option)}
                  tabIndex={0} // Make each dropdown item focusable
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleItemClick(option);
                    }
                  }}
                >
                  {typeof option === "object"
                    ? t(option?.title) || option?.name
                    : option}
                </li>
              ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Dropdown;
