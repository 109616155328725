// src/components/CustomButton
import { Component, useContext, useEffect, useState } from "react";
import classes from "./CustomButton.module.css";
import { useNavigate } from "react-router-dom";

export default function BackToDashboard({
  elementType,
  title,
  icon,
  iconSize,
  variant,
  minWidth,
  reverseIcon,
  disabled,
  to,
  ...props
}) {
  const Element = elementType ? elementType : "button";
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchedUserData = {
      userType: localStorage.getItem("userType"),
      organizationId: localStorage.getItem("organizationId"),
    };
    setUserData(fetchedUserData);
  }, []);

  const navigate = useNavigate();

  const navigateFunction = () => {
    switch (userData.userType) {
      case "organization":
      case "orgUser":
        navigate(to || -1);
        break;
      case "user":
        navigate(to || "/dashboard");
        break;
    }
  };

  return (
    <Element
      className={`${classes.customButton} ${variant ? classes[variant] : ""} ${
        disabled ? classes.disabled : ""
      }`}
      style={{
        minWidth: minWidth || "",
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1,
      }}
      disabled={Element === "button" ? disabled : undefined}
      onClick={navigateFunction}
      {...props}
    >
      {!reverseIcon && icon && (
        <img
          src={icon}
          alt={title}
          style={{ width: iconSize + "px" }}
          className={classes.icon}
        />
      )}
      <span className={classes.title}>{title}</span>
      {reverseIcon && icon && (
        <img
          src={icon}
          alt={title}
          style={{ width: iconSize + "px" }}
          className={classes.icon}
        />
      )}
    </Element>
  );
}
