import { useContext } from "react";
import { useTranslation } from "react-i18next";
import classes from "./CustomInput.module.css";

const CustomInput = ({
  title,
  type,
  textAlign,
  thick,
  flex,
  errorText,
  onChange,
  readonly,
  ...props
}) => {
  const { t } = useTranslation("general");

  const handleChange = (e) => {
    let { value } = e.target;

    if (type === "money") {
      // Allow only numbers, commas, and spaces
      const regex = /^[\d,. ]*$/;
      if (!regex.test(value)) {
        return; // Return early if the value doesn't match the allowed characters
      }
    }

    props.onChange(e);
  };

  const showError = !props.value && errorText;

  return (
    <div className={flex ? classes.inputContainer : ""}>
      {title && <label className={classes.label}>{title}</label>}
      {showError && <div className="error-message">{t(errorText)}</div>}
      {type === "textarea" ? (
        <textarea
          className={`${classes.textInput} ${thick ? classes.thick : ""}`}
          {...props}
          onChange={onChange || handleChange}
        />
      ) : (
        <input
          className={`${classes.textInput} ${thick ? classes.thick : ""}`}
          {...props}
          type={type}
          onChange={onChange || handleChange}
          readOnly={readonly}
        />
      )}
    </div>
  );
};

export default CustomInput;
