// App.js
import { useState, useEffect, useContext } from "react";
import { jwtDecode as jwt_decode } from "jwt-decode";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom";
// import InvoiceTable from "./components/InvoiceTable";
import Register from "./components/Register";
import Login from "./components/Login";
import IssuedAndImportedBooks from "./pages/organization-pages/IssuedAndImportedBooks";
import IssuedBooks from "./pages/organization-pages/IssuedBooks";
import DashboardPage from "./pages/user-pages/DashboardPage";
import InvoicesManagement from "./pages/user-pages/InvoicesManagement";
import ContainerPrices from "./pages/user-pages/ContainerPrices";
import ViewInvoice from "./pages/user-pages/ViewInvoice";
import PrintInvoices from "./pages/user-pages/PrintInvoices";
import DataProvider from "./utils/DataContext";
import ScrollToTop from "./components/ScrollToTop";
import PrintDocuments from "./components/PrintDocuments";
import Header from "./components/Header";
import Profile from "./pages/user-pages/Profile";
import VerfiyEmail from "./pages/general-pages/VerfiyEmail";
import ResetPassword from "./pages/general-pages/ResetPassword";
import SuccessPage from "./pages/general-pages/SuccessPage";
import SuppliersPage from "./pages/user-pages/SuppliersPage";
import Employees from "./pages/organization-pages/Employees";
import OrganizationDashboard from "./pages/organization-pages/OrganizationDashboard";
import InvoicesOfShippedContainers from "./pages/organization-pages/InvoicesOfShippedContainers";
import Development from "./pages/Development";
import AdminsLogin from "./pages/admin-pages/AdminsLogin";
import ControlPanel from "./pages/admin-pages/ControlPanel";
import UsersPage from "./pages/admin-pages/UsersPage";
import AdminsPage from "./pages/admin-pages/AdminsPage";
import UserAuthProvider, { UserAuth } from "./utils/AuthContext";
import EditContainer from "./pages/user-pages/EditContainer";
import CustomersPage from "./pages/general-pages/CustomersPage";
import SubCompanies from "./pages/organization-pages/SubCompanies";
import OrgShippedContainers from "./pages/organization-pages/OrgShippedContainers";
import ViewShippedContainer from "./pages/organization-pages/ViewShippedContainer";
import CreateShippedContainerPage from "./pages/organization-pages/CreateShippedContainerPage";
import ViewMultiContainerInvoice from "./pages/organization-pages/ViewMultiContainerInvoice";
import MessagePage from "./pages/general-pages/MessagePage";
import AddPermit from "./pages/general-pages/AddPermit";
import MissingPage from "./pages/MissingPage";
import AnalyzePdf from "./pages/AnalyzePdf";

const Layout = ({ element, showHeader, setIsAuthenticated }) => {
  return (
    <DataProvider>
      {showHeader && <Header setIsAuthenticated={setIsAuthenticated} />}
      {element}
    </DataProvider>
  );
};
const App = () => {
  const getShowHeader = (path) => {
    const headerRoutes = [
      "/dashboard",
      "/containerPrices",
      "/invoice/:id",
      "/print",
      "/printDocuments",
      "/profile",
      "/ControlPanel",
      "/issuedBooks",
      "/importedBooks",
      "/employees",
      "/organization-dashboard",
      "/development",
      "/viewSuppliersPage",
      "/customers",
      "/subCompanies",
      "/orgShippedContainers",
      "/invoicesOfShippedContainers",
      "/editContainer/:id",
      "/shippedContainer/:id",
      "/analyzepdf",
    ];
    return headerRoutes.some((route) => path.match(route));
  };

  function ProtectedResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const id = searchParams.get("id");

    if (token && id) {
      return <ResetPassword />;
    } else {
      return <Navigate to="/page-not-found" />;
    }
  }

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("SuperAdminToken");

    localStorage.removeItem("userType");

    localStorage.removeItem("userId");
    localStorage.removeItem("supplierId");
    localStorage.removeItem("customerId");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("organizationId");

    setIsAuthenticated(null);
    setIsAuthenticatedAdmin(null);
  };

  // Utility function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Failed to decode token", error);
      return true; // If there's an error decoding, consider the token expired
    }
  };

  // Check for valid token on initial load and whether it's expired
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage.getItem("token");
    return token && !isTokenExpired(token); // Check if token is valid and not expired
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    // console.log("token", token);
    if (token && isTokenExpired(token)) {
      logout();
    }
  }, []);

  const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(() => {
    const adminToken =
      localStorage.getItem("SuperAdminToken") ||
      localStorage.getItem("adminToken");
    return adminToken ? true : false; // If there's a token, user is authenticated
  });

  const ProtectedRoute = ({ isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  const AdminProtectedRoute = ({ isAuthenticatedAdmin, children }) => {
    return isAuthenticatedAdmin ? children : <Navigate to="/admin-login" />;
  };

  return (
    <Router>
      <>
        <Routes>
          <Route
            path="*"
            element={
              <UserAuthProvider>
                <Routes>
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<DashboardPage />}
                          showHeader={getShowHeader("/dashboard")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/viewSuppliersPage"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<SuppliersPage />}
                          showHeader={getShowHeader("/viewSuppliersPage")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/editContainer/:id"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<EditContainer />}
                          showHeader={getShowHeader("/editContainer/:id")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/invoiceManagement"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={
                            <InvoicesManagement
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          }
                          showHeader={getShowHeader("/dashboard")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/message-page"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={
                            <MessagePage
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          }
                          showHeader={getShowHeader("/organization-dashboard")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/organization-dashboard"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={
                            <OrganizationDashboard
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          }
                          showHeader={getShowHeader("/organization-dashboard")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/development"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={
                            <Development
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          }
                          showHeader={getShowHeader("/organization-dashboard")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/issuedAndImportedBooks"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={
                            <IssuedAndImportedBooks
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          }
                          showHeader={getShowHeader("/dashboard")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/issuedBooks"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={
                            <IssuedBooks
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          }
                          showHeader={getShowHeader("/issuedBooks")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/employees"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={
                            <Employees
                              setIsAuthenticated={setIsAuthenticated}
                            />
                          }
                          showHeader={getShowHeader("/employees")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/containerPrices"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<ContainerPrices />}
                          showHeader={getShowHeader("/containerPrices")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/invoice/:id"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<ViewInvoice />}
                          showHeader={getShowHeader("/invoice/:id")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/createShippedContainerPage"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<CreateShippedContainerPage />}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/print"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<PrintInvoices />}
                          showHeader={getShowHeader("/print")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/permit"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<AddPermit />}
                          showHeader={getShowHeader("/dashboard")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/printDocuments"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<PrintDocuments />}
                          showHeader={getShowHeader("/printDocuments")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<Profile />}
                          showHeader={getShowHeader("/profile")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/viewSuppliersPage"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<SuppliersPage />}
                          showHeader={getShowHeader("/viewSuppliersPage")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/customers"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<CustomersPage />}
                          showHeader={getShowHeader("/customersPage")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/orgShippedContainers"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<OrgShippedContainers />}
                          showHeader={getShowHeader("/orgShippedContainers")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/invoicesOfShippedContainers"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<InvoicesOfShippedContainers />}
                          showHeader={getShowHeader("/invoicesOfShippedContainers")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/shippedContainer/:id"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<ViewShippedContainer />}
                          showHeader={getShowHeader("/shippedContainer/:id")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/viewMultiContainerInvoice"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<ViewMultiContainerInvoice />}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/subCompanies"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<SubCompanies />}
                          showHeader={getShowHeader("/subCompanies")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/analyzepdf"
                    element={
                      <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Layout
                          element={<AnalyzePdf />}
                          showHeader={getShowHeader("/analyzepdf")}
                          setIsAuthenticated={setIsAuthenticated}
                        />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="*" element={<MissingPage />} />
                </Routes>
              </UserAuthProvider>
            }
          />
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route
            path="/admin-login"
            element={
              <AdminsLogin
                isAuthenticatedAdmin={isAuthenticatedAdmin}
                setIsAuthenticatedAdmin={setIsAuthenticatedAdmin}
              />
            }
          />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/admins" element={<AdminsPage />} />
          <Route
            path="/ControlPanel"
            element={
              <AdminProtectedRoute isAuthenticatedAdmin={isAuthenticatedAdmin}>
                <Layout
                  element={
                    <ControlPanel
                      setIsAuthenticated={setIsAuthenticatedAdmin}
                    />
                  }
                  showHeader={getShowHeader("/ControlPanel")}
                  setIsAuthenticated={setIsAuthenticatedAdmin}
                />
              </AdminProtectedRoute>
            }
          />
          <Route path="/register" element={<Register />} />
          <Route
            path="/login"
            element={
              <Login
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route path="/verify-email" element={<VerfiyEmail />} />
          <Route path="/successPage" element={<SuccessPage />} />
        </Routes>
        <ScrollToTop />
      </>
    </Router>
  );
};

export default App;
