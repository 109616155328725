// src/pages/DashboardPage.js
import { Link } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";
import { useContext } from "react";
import { UserAuth } from "../../utils/AuthContext";

export default function DashboardPage() {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();

  const { userData } = useContext(UserAuth);

  const userType = userData?.userType;

  return (
    <div className="control-panel-page" style={{ direction: currentDirection }}>
      <div className="control-panel-container">
        <div className="user-section">
          <div className="user">
            <span className="user-text">
              {userType === "organization"
                ? `${t("organization")}:`
                : `${t("user")}:`}
            </span>
            <span className="user-text">{`${localStorage.getItem(
              "username"
            )}`}</span>
          </div>
        </div>
        <h3>{t("controlPanel")}</h3>
        <div className="control-panel-actions">
        
          <CustomButton
            title={t("loadingAndUnloading")}
            variant="blank"
            elementType={Link}
            to="/orgShippedContainers"
          />

          <CustomButton
            title={t("addPermit")}
            variant="blank"
            elementType={Link}
            to="/permit"
          />

          <CustomButton
            title={t("containersAndInvoices")}
            variant="blank"
            elementType={Link}
            to="/InvoiceManagement"
          />

          <CustomButton
            title={t("suppliers")}
            variant="blank"
            elementType={Link}
            to="/viewSuppliersPage"
          />

          <CustomButton
            title={t("customers")}
            variant="blank"
            elementType={Link}
            to="/customers"
          />

          <CustomButton
            title={t("subCompanies")}
            variant="blank"
            elementType={Link}
            to="/subCompanies"
          />

          {/* <CustomButton
            title={t("importedAndExportedBooks")}
            variant="blank"
            onClick={viewIssuedAndImportedBooksPage}
          /> */}

          <CustomButton
            title={t("employees")}
            variant="blank"
            elementType={Link}
            to="/employees"
          />

        </div>
      </div>
    </div>
  );
}
