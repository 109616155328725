// src/pages/organization-pages/CreateShippedContainerPage.js
import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { DataContext } from "../../utils/DataContext";
import { UserAuth } from "../../utils/AuthContext";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";
import { useNavigate } from "react-router-dom";
import AddShippedContainerModal from "./AddShippedContainerModal";
import ShippedContainerCard from "../../components/ShippedContainerCard";
import BackToDashboard from "../../components/BackToDashboard";
import ErrorBox from "../../components/ErrorBox";
import Loader from "../../components/Loader";
import CustomInput from "../../components/CustomInput";
import Dropdown from "../../components/Dropdown";
import CustomButton from "../../components/CustomButton";
import previousIcon from "../../assets/previous_icon.png";
import nextIcon from "../../assets/next_icon.png";
import addIcon from "../../assets/add_icon.png";
import { getCleanedInvoiceNumber } from "../../utils/getCleanedInvoiceNumber";
import classes from "../../components/Modal.module.css";
import { sanitizeNumber } from "../../utils/sanitizeNumber";
import saveIcon from "../../assets/save_icon.png";
import closeIcon from "../../assets/close_icon.png";

const CreateShippedContainerPage = ({ setShowInputFields }) => {
  const { t } = useTranslation("general");
  const { currentDirection } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("currentDirection: ", currentDirection);
  }, []);

  const [showAddShippedContainerModal, setShowAddShippedContainerModal] =
    useState(false);

  const [currentContainer, setCurrentContainer] = useState(null);
  const [containers, setContainers] = useState([]);

  const updateContainers = () => {
    setContainers([
      ...containers,
      {
        containerNumber: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",

        shipper: "",
        material: "",
        numberOfBoxes: 0,
        unitPrice: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        howWasPapersReceived: "",
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      },
    ]);
  };

  // Handle opening the modal for a specific container
  const openModal = (index) => {
    setCurrentContainer(index);
    setShowAddShippedContainerModal(true);
  };

  // Handle saving container details from the modal
  // const saveContainerDetails = (details) => {
  //   const updatedContainers = [...containers];
  //   if (currentContainer === null) {
  //     updatedContainers.push(details);
  //   } else {
  //     updatedContainers[currentContainer] = details;
  //   }
  //   setContainers(updatedContainers);
  //   setShippedContainers(updatedContainers);
  //   // setShowModal(false);
  // };

  // // Add a new empty container
  // const addNewContainer = () => {
  //   setCurrentContainer(null);
  //   // setShowModal(true);
  // };

  const [loadingModal, setLoadingModal] = useState(false);
  const {
    suppliers,
    getSuppliers,
    setSupplierModal,
    selectedSupplier,
    error,
    setError,
    shippedContainers,
    setShippedContainers,
    selectedCustomer,
    customers,
    handleSelectSupplier,
    handleSelectCustomer,
    setSupplierFields,
    setNetworkError,
    setShippedContainersCount,
    subCompanies,
    selectedSubCompany,
    handleSelectSubCompany,
    getSubCompanies,
    getCustomers,
  } = useContext(DataContext);

  const { userData } = useContext(UserAuth);

  const [invoiceDocs, setInvoiceDocs] = useState([]);
  const pdfUploaderRef = useRef(null);

  const [howWasPapersReceivedStatus, setHowWasPapersReceivedStatus] = useState({
    title: "receivedByUs",
    value: "receivedByUs",
  });

  const [invoiceNumber, setInvoiceNumber] = useState(""); // store the invoice number globally
  const [received, setReceived] = useState(""); // store the received globally

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
      "Enter",
    ];
    const isNumberOrSymbol = /\d|\,|\./.test(e.key);

    if (!isNumberOrSymbol && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const [page, setPage] = useState(1); // Track the current page

  const [inputs, setInputs] = useState();

  const handleInvoiceNumberChange = (e) => {
    const { value } = e.target;
    setInvoiceNumber(value); // Update the global invoice number
  };

  const handleReceivedChange = (e) => {
    const { value } = e.target;
    setReceived(value); // Update the global received value
  };
  useEffect(() => {
    console.log("currentDirection: ", currentDirection);
  }, []);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        containerNumber: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",
        shipper: "",
        material: "",
        numberOfBoxes: 0,
        unitPrice: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        howWasPapersReceived: "",
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      },
    ]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    // Convert 'received' and 'finalAmountForCustomer' to Float before setting them in the state
    const updatedValue =
      name === "finalAmountForCustomer" ? parseFloat(value) : value;

    const updatedInputs = [...inputs];
    updatedInputs[index][name] = updatedValue;
    setInputs(updatedInputs);
  };

  const handleAddRow = async () => {
    try {
      const token = userData?.token;
      const userId = parseInt(userData?.userId, 10);

      if (!userId) {
        console.error("User ID not found in localStorage");
        return;
      }

      setLoadingModal(true);

      // Prepare the payload
      const payload = {
        supplierId: selectedSupplier?.id,
        customerId: selectedCustomer?.id,
        subCompanyId: selectedSubCompany?.id,
        createdBy: userId,
        invoiceNumber: getCleanedInvoiceNumber(invoiceNumber),
        received: sanitizeNumber(received),
        left:
          sanitizeNumber(received) -
          inputs.reduce(
            (acc, curr) => acc + parseFloat(curr.amountForSupplier || 0),
            0
          ),
        containers: inputs.map((input) => ({
          containerNumber: input.containerNumber,
          expectedArrivalDate: input.expectedArrivalDate?.toISOString(),
          finalDestination: input.finalDestination,
          shipper: input.shipper,
          material: input.material,
          numberOfBoxes: parseInt(input.numberOfBoxes, 10),
          exitDate: input.exitDate?.toISOString(),
          dateOfEntry: input.dateOfEntry?.toISOString(),
          shippingDate: input.shippingDate?.toISOString(),
          permitsReceivingDate: input.permitsReceivingDate?.toISOString(),
          amountForSupplier: sanitizeNumber(input.amountForSupplier),
          abstractAmountForCustomer: sanitizeNumber(
            input.abstractAmountForCustomer
          ),
          finalAmountForCustomer: sanitizeNumber(input.finalAmountForCustomer),
          notes: input.notes,
        })),
      };

      // Send the request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/createShippedContainer`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      // Handle the server response
      if (response.status === 200) {
        setShippedContainers(response.data.containers);
        navigate("/OrgShippedContainers");
      }
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred");
    } finally {
      setLoadingModal(false);
    }
  };

  const handleCancel = () => {
    // setShowInputFields(false);
    setInputs([
      {
        containerNumber: "",
        expectedArrivalDate: new Date(),
        finalDestination: "",

        shipper: "",
        material: "",
        numberOfBoxes: 0,
        unitPrice: 0,
        exitDate: new Date(),
        dateOfEntry: new Date(),
        shippingDate: new Date(),
        permitsReceivingDate: new Date(),
        howWasPapersReceived: "",
        amountForSupplier: "",
        abstractAmountForCustomer: "",
        finalAmountForCustomer: "",
        notes: "",
      },
    ]);
    navigate("/OrgShippedContainers");
  };
  return (
    <>
      <div className="add-shipped-container-page">
        <div className="add-shipped-container-top-actions">
          <div className="user-logout">
            <BackToDashboard
              title={t("back")}
              variant="blank"
              icon={currentDirection === "ltr" ? previousIcon : nextIcon}
              iconSize={14}
            />
          </div>
        </div>

        <h3 className={"add-shipped-container-title"}>{t("addContainer")}</h3>

        {loadingModal ? <Loader /> : null}
        {error && !loadingModal && <ErrorBox>{error}</ErrorBox>}

        <div className={` add-shipped-container-invoice-part`}>
          <div className="add-shipped-container-header">
            <section className="add-shipped-container-header-section">
              <span>{t("supplier")}: </span>
              <Dropdown
                options={suppliers}
                selected={
                  selectedSupplier.id !== "All"
                    ? selectedSupplier
                    : suppliers[0]
                }
                setSelected={handleSelectSupplier}
                flex
                page="shippedContainers"
                getData={getSuppliers}
                errorText={error}
              />
            </section>
            <section className="add-shipped-container-header-section">
              <span>{t("customer")}: </span>
              <div className={classes.invoiceNumberContainer}>
                {selectedCustomer?.id ? (
                  <Dropdown
                    options={customers}
                    selected={
                      selectedCustomer.id !== "All"
                        ? selectedCustomer
                        : customers[0]
                    }
                    getData={getCustomers}
                    setSelected={handleSelectCustomer}
                    //   thick
                    flex={true}
                    errorText={error}
                  />
                ) : (
                  <span>{t("noCustomersFound")}</span>
                )}
              </div>
            </section>
          </div>
          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.modalSubHeaderDivider}>
              <span className={classes.modalSubHeaderDividerFirstDiv}>
                {t("invoiceDetails")}:{" "}
              </span>
              <span className={classes.modalSubHeaderDividerSecondDiv}></span>
            </div>
          </section>
          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.inputContainer}>
              <span>{t("invoiceNumber")}: </span>
              <div className={classes.invoiceNumberContainer}>
                <CustomInput
                  name="invoiceNumber"
                  value={invoiceNumber}
                  onChange={handleInvoiceNumberChange}
                  flex={true}
                />
              </div>
            </div>
          </section>
          <section className={`${classes.section} ${classes.invoiceNumber}`}>
            <div className={classes.inputContainer}>
              <span>{t("received")}: </span>
              <div className={classes.invoiceNumberContainer}>
                <CustomInput
                  name="received"
                  value={received}
                  onChange={handleReceivedChange}
                  flex={true}
                />
              </div>
            </div>
          </section>
          <section className={classes.section}>
            <div className={classes.inputContainer}>
              <span>{t("subCompany")}: </span>
              <Dropdown
                options={subCompanies}
                selected={selectedSubCompany}
                setSelected={handleSelectSubCompany}
                flex={true}
                getData={getSubCompanies}
              />
            </div>
          </section>
          <div className="add-container-button-alignment">
            <CustomButton
              onClick={() => openModal(true)}
              variant="blank"
              title={
                inputs?.length > 1
                  ? t("addAnotherContainer")
                  : t("addContainer")
              }
              icon={addIcon}
              iconSize={16}
              disabled={loadingModal}
            />
          </div>
        </div>

        {inputs?.length > 0 && (
          <>
            <section className={`${classes.section} ${classes.invoiceNumber}`}>
              <div className={classes.modalSubHeaderDivider}>
                <span className={classes.modalSubHeaderDividerFirstDiv}>
                  {t("containers")}:{" "}
                </span>
                <span className={classes.modalSubHeaderDividerSecondDiv}></span>
              </div>
            </section>
            <div className="all-containers">
              {inputs?.map((item) => (
                <ShippedContainerCard
                  containerNumber={item.containerNumber}
                  amount={item.amount}
                />
              ))}

              {/* <ShippedContainerCard containerNumber="ABC123" amount="1500" />
                  <ShippedContainerCard containerNumber="XYZ456" amount="2500" />
                  <ShippedContainerCard containerNumber="REQ456" amount="3200" />
                  <ShippedContainerCard containerNumber="RUZ456" amount="6700" /> */}
            </div>
          </>
        )}

        <div className={"add-shipped-container-add-another"}>
          <section className={classes.actions}>
            <CustomButton
              onClick={handleAddRow}
              title={t("confirm")}
              icon={saveIcon}
              iconSize={16}
              disabled={loadingModal}
            />
            <CustomButton
              title={t("cancel")}
              onClick={handleCancel}
              variant="blank"
              icon={closeIcon}
              iconSize={14}
            />
          </section>
        </div>
      </div>
      {/* Render Modal */}
      {showAddShippedContainerModal && (
        <AddShippedContainerModal
          inputs={inputs}
          setInputs={setInputs}
          onClose={() => setShowAddShippedContainerModal(false)}
          setShowAddShippedContainerModal={() =>
            setShowAddShippedContainerModal(false)
          }
        />
      )}
    </>
  );
};

export default CreateShippedContainerPage;
