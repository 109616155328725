import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useContext,
} from "react";
import { useDocs } from "../utils/useDocs";
import classes from "./PDFUploader.module.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CustomButton from "./CustomButton";

import AllDocumentsSlider, { DIRECTION } from "./AllDocumentsSlider";
import PDFSlider from "./PDFSlider";
import ImagesSlider from "./ImagesSlider";

import uploadIcon from "../assets/upload_icon.png";
import addIcon from "../assets/add_icon.png";
import { DataContext } from "../utils/DataContext";

const PDFUploader = forwardRef((props, ref) => {
  const { handlePrepareDocs } = useDocs();
  const { t } = useTranslation("general");
  const [docsFields, setDocsFields] = useState({
    count: 1,
    max: 10,
  });

  const handleUploadDocs = async (invoiceId) => {
    // console.log("handleUploadDocs called with invoiceId:", invoiceId);
    const readyToUploadDocs = new FormData();
    readyToUploadDocs.append(
      "imageType",
      `invoice ${props?.invoiceNumber} documents`
    );

    // console.log("readyToUploadDocs", readyToUploadDocs);

    readyToUploadDocs.append("invoiceId", invoiceId);

    if (props?.tempDocs.length) {
      props?.tempDocs.forEach((document) => {
        readyToUploadDocs.append("images", document);
      });
    } else {
      return;
    }
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userId"), 10);

    try {
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_URL}/uploadImages`,
        readyToUploadDocs,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Id": userId,
          },
        }
      );

      if (!uploadResponse?.data) {
        console.error("Upload failed");
      } else {
        const updatedDocuments = [
          ...props.invoiceDocs,
          ...uploadResponse?.data?.images,
        ];
        props.setInvoiceDocs(updatedDocuments);
        props?.setTempDocs([]);
        setDocsFields({
          count: 1,
          max: 10,
        });
      }
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const generateUploadInputs = () => {
    return Array.from({ length: docsFields.count }).map((_, index) => (
      <div className={classes.inputContainer} key={index}>
        <input
          accept="image/*,application/pdf"
          type="file"
          onChange={(e) => {
            handlePrepareDocs(
              e,
              `Invoice ${props?.invoiceNumber} - image ${index + 1}`
            );
            props?.setTempDocs((prev) => [...prev, e.target.files[0]]);
            console.log("e.target.files[0]", e.target.files[0]);
          }}
          className={classes.fileInput}
          name="images"
          id={`invoiceDoc${index + 1}`}
        />
        <label htmlFor={`invoiceDoc${index + 1}`}>
          <p className={classes.customFileInput}>
            <img src={uploadIcon} alt={`Upload image ${index + 1}`} />
            <span>
              {t("uploadDocument")} {index + 1}
            </span>
          </p>
        </label>
        {props?.tempDocs[index] && (
          <span className={classes.fileName}>
            {t("fileName")}: {props?.tempDocs[index]?.name?.slice(0, 30)}
          </span>
        )}
      </div>
    ));
  };

  useImperativeHandle(ref, () => ({
    handleUploadDocs,
  }));

  return (
    <>
      <section className={classes.section}>
        <>
          {props?.mode === "upload" ? (
            <div className={classes.pdfUploaderSection}>
              <span>{t("uploadDocs")}: </span>
              <div className={classes.pdfUploaderSectionActions}>
                {generateUploadInputs()}
                {docsFields.count < docsFields.max && (
                  <CustomButton
                    title={t("addAnotherDocument")}
                    variant="blank"
                    onClick={() =>
                      setDocsFields((prev) => ({
                        ...prev,
                        count: prev.count < 10 ? ++prev.count : prev.count,
                      }))
                    }
                    icon={addIcon}
                  />
                )}
              </div>
            </div>
          ) : props?.mode === "show" ? (
            <>
              {props?.documents.length ? (
                <AllDocumentsSlider
                  documents={props?.documents}
                  title="invoiceAllDocs"
                />
              ) : null}
            </>
          ) : (
            <>
              <div className={classes.pdfUploaderSection}>
                <AllDocumentsSlider
                  documents={props?.documents}
                  title="invoiceCurrentDocs"
                />

                <div className={classes.pdfUploaderSectionActions}>
                  <span>{t("uploadDocs")}: </span>
                  {generateUploadInputs()}
                  {docsFields.count < docsFields.max && (
                    <CustomButton
                      title={t("addAnotherDocument")}
                      variant="blank"
                      onClick={() =>
                        setDocsFields((prev) => ({
                          ...prev,
                          count: prev.count < 10 ? ++prev.count : prev.count,
                        }))
                      }
                      icon={addIcon}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </>
      </section>
    </>
  );
});

export default PDFUploader;
